import { Button, IconButton } from "@chakra-ui/button";
import { useMediaQuery } from "@chakra-ui/media-query";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import React, { Fragment, useState } from "react";
import {
  AiFillClockCircle,
  AiOutlineInstagram,
  AiOutlineMenu,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsCalendar3, BsCashCoin } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./style.css";

const NavBar = () => {
  const [activeBtn, setActiveBtn] = useState("home");
  const [is786] = useMediaQuery("(min-width: 786px)");
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  // const initial = {
  //   search: "",
  //   range: [0, 500],
  //   date: [null, null],
  //   place: "",
  //   category: "",
  // };

  // const [form, setForm] = useState(initial);
  const onClose = () => {
    setIsOpen(false);
  };
  // const colourStyles = {
  //   menu: (styles) => ({
  //     ...styles,
  //     backgroundColor: "transparent",
  //     width: "100% !important",
  //   }),
  //   control: (styles) => ({
  //     ...styles,
  //     backgroundColor: "transparent",
  //     border: "unset",
  //     width: "100% !important",
  //     borderBottom: "1px solid #ff9966",
  //     borderRadius: 0,
  //     color: "white",
  //     fontSize: 18,
  //     opacity: 0.8,
  //     fontFamily: "gilroy-regular",
  //     ":focus": {
  //       borderLeft: "unset !important",
  //       borderTop: "unset !important",
  //       borderRight: "unset !important",
  //       borderBottom: "2px solid #dd6b20",
  //       boxShadow: "none !important",
  //     },
  //     ":hover": {
  //       borderBottom: "1px solid #cc7a52",
  //     },
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isSelected ? "white" : isFocused ? "white" : "black",
  //       color: isSelected ? "black" : isFocused ? "black" : "white",
  //       cursor: "default",

  //       ":active": {
  //         ...styles[":active"],
  //         backgroundColor: isSelected ? "white" : isFocused ? "black" : "white",
  //         color: isSelected ? "white" : isFocused ? "black" : "white",
  //       },
  //     };
  //   },
  //   indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  //   input: (styles) => ({ ...styles, color: "white" }),
  //   placeholder: (styles) => ({ ...styles, color: "white" }),
  //   singleValue: (styles, { data }) => ({ ...styles, color: "white" }),
  // };
  const searchClient = algoliasearch(
    "Z6XJG00H9C",
    "b53fa8b7af6db175c85942072e92d76b",
  );
  function Hit(props) {
    return (
      <div
        style={{ marginBottom: "2%", cursor: "pointer" }}
        onClick={() => {
          history.push("/" + props.hit.objectID);
          onClose();
        }}>
        <HStack spacing={5} alignItems="flex-start">
          <img
            className="hit-image"
            src={props.hit.image}
            alt={props.hit.title}
            style={{
              width: "28%",
              height: 150,
              borderRadius: 12,
              objectFit: "cover",
            }}
          />
          <div>
            <div className="hit-name" style={{ color: "white" }}>
              <Text
                fontSize={{ base: "sm", sm: "xl" }}
                my="1"
                fontWeight="bold">
                <Highlight attribute="title" hit={props.hit} />
              </Text>
            </div>
            <VStack spacing={2} alignItems="flex-start">
              <Text
                color="#ffffff"
                fontSize={{ base: "xs", sm: "md" }}
                style={{
                  fontFamily: "gilroy-medium",
                }}>
                {props.hit.category}
              </Text>

              <HStack spacing={1} alignItems="center" my={3}>
                <MdLocationOn color="#ff9966" />
                <Text
                  color="#ffffff"
                  fontSize={{ base: "xs", sm: "md" }}
                  style={{
                    fontFamily: "gilroy-medium",
                  }}>
                  {props.hit.location}
                </Text>
              </HStack>
              <HStack spacing={1} alignItems="center" my={3}>
                <BsCashCoin color="#ff9966" />
                <Text
                  fontSize={{ base: "xs", sm: "md" }}
                  color="#fff"
                  style={{ fontFamily: "gilroy-medium" }}>
                  ${props.hit.prices.join(" , $")}
                </Text>
              </HStack>

              <Stack direction={{ base: "column", sm: "row" }}>
                <HStack spacing={1} alignItems="center">
                  <BsCalendar3 color="#ff9966" />
                  <Text color="#fff" style={{ fontFamily: "gilroy-medium" }}>
                    {moment(parseInt(props.hit.dateTime.start)).format(
                      "DD/MM/YYYY",
                    )}
                  </Text>
                </HStack>
                <HStack spacing={1} alignItems="center">
                  <AiFillClockCircle color="#ff9966" />
                  <Text color="#fff" style={{ fontFamily: "gilroy-medium" }}>
                    {moment(parseInt(props.hit.dateTime.start)).format(
                      "hh:mm A",
                    )}{" "}
                    ~{" "}
                    {moment(parseInt(props.hit.dateTime.end)).format("HH:mm A")}
                  </Text>
                </HStack>
              </Stack>
            </VStack>
          </div>
        </HStack>
      </div>
    );
  }

  return (
    <div className="nav-bar">
      <Stack direction={{ base: "column", sm: "row" }}>
        <div style={{ alignSelf: !is786 ? "center" : "flex-start" }}>
          <Link to="/">
            <img
              src="/images/logo.svg"
              alt="Logo"
              style={{ height: 80, width: 180 }}
              onClick={() => {
                setActiveBtn("home");
              }}
            />
          </Link>
        </div>
        <HStack flexGrow={1} justifyContent="flex-end">
          {is786 ? (
            <Fragment>
              {!(
                pathname === "/event/checkout" ||
                Array.isArray(pathname.match("/events/payment/success/:events"))
              ) && (
                <>
                  <Button
                    className={activeBtn === "home" && "btn-pressed behavior"}
                    variant="ghost"
                    fontSize={{ base: 8, sm: "md" }}
                    color="#72757A"
                    onClick={() => {
                      setActiveBtn("home");
                    }}>
                    <Link to="/"> Home</Link>
                  </Button>
                  <Button
                    className={
                      activeBtn === "allEvents" && "btn-pressed behavior"
                    }
                    variant="ghost"
                    color="#72757A"
                    onClick={() => {
                      setActiveBtn("allEvents");
                    }}>
                    <Link to="/events/all">All Events</Link>
                  </Button>
                </>
              )}
              {pathname === "/" && (
                <Button
                  className={
                    activeBtn === "mobileApp" && "btn-pressed behavior"
                  }
                  variant="ghost"
                  color="#72757A"
                  style={{ marginRight: "2%" }}
                  onClick={() => {
                    setActiveBtn("mobileApp");
                    const titleElement = document.getElementById("MobileApp");
                    titleElement.scrollIntoView({
                      top: -100,
                      behavior: "smooth",
                    });
                  }}>
                  Mobile App
                </Button>
              )}
            </Fragment>
          ) : (
            !(
              pathname === "/event/checkout" ||
              Array.isArray(pathname.match("/events/payment/success/:events"))
            ) && (
              <div style={{ flexGrow: 1 }}>
                <Menu>
                  <MenuButton
                    className="social-btn"
                    as={IconButton}
                    aria-label="Options"
                    icon={<AiOutlineMenu fontSize={20} />}
                    variant="ghost"
                  />
                  <MenuList>
                    <Link to="/">
                      <MenuItem
                        className="social-btn "
                        style={{ paddingBlock: 12 }}>
                        Home
                      </MenuItem>
                    </Link>

                    <Link to="/events/all">
                      <MenuItem
                        className="social-btn"
                        style={{ paddingBlock: 12 }}>
                        All Events{" "}
                      </MenuItem>
                    </Link>

                    {pathname === "/" && (
                      <MenuItem
                        className="social-btn"
                        style={{ paddingBlock: 12 }}
                        onClick={() => {
                          const titleElement =
                            document.getElementById("MobileApp");
                          titleElement.scrollIntoView({
                            top: -100,
                            behavior: "smooth",
                          });
                        }}>
                        Mobile App
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </div>
            )
          )}
          {/* <IconButton
            size="lg"
            className="social-btn"
            variant="ghost"
            aria-label="twitter"
            icon={<BiSearch fontSize={22} />}
            onClick={() => {
              setIsOpen(true);
            }}
          /> */}
          {!(
            pathname === "/event/checkout" ||
            Array.isArray(pathname.match("/events/payment/success/:events"))
          ) && (
            <>
              <IconButton
                size="lg"
                className="social-btn"
                variant="ghost"
                aria-label="twitter"
                icon={<AiOutlineTwitter fontSize={22} />}
                onClick={() => {
                  window.open("https://twitter.com/tempoeventsapp", "_blank");
                }}
              />
              <IconButton
                size="lg"
                className="social-btn"
                variant="ghost"
                aria-label="Instagram"
                icon={<AiOutlineInstagram fontSize={24} />}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/tempoeventsapp/",
                    "_blank",
                  );
                }}
              />
            </>
          )}

          {pathname.match("/events/payment/success/:events") && (
            <div>
              <Text
                color="#5BAD4E"
                fontFamily="gilroy-bold"
                fontSize={{ base: 24, lg: 36, sm: 32 }}
                textAlign={{ base: "center", sm: "right" }}>
                Order Confirmed
              </Text>
              <Text
                fontFamily="gilroy-medium"
                fontSize={{ base: 14, lg: 18, sm: 16 }}
                textAlign={{ base: "center", sm: "right" }}>
                Thank you for purchasing the ticket from Tempo
              </Text>
            </div>
          )}
        </HStack>
      </Stack>
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent backgroundColor="black">
          <ModalCloseButton color="white" onClick={() => {}} />
          <ModalBody m={{ base: "2", sm: "24" }}>
            <div className="ais-InstantSearch">
              <InstantSearch
                indexName="prod_TEMPO"
                searchClient={searchClient}
                className="autofill">
                <SearchBox />
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  spacing={5}
                  alignItems="flex-start"
                  mt="4">
                  <div className="left-panel">
                    <RefinementList attribute="category" />
                    <Configure hitsPerPage={8} />
                  </div>
                  <div className="right-panel">
                    <Hits hitComponent={Hit} />
                    <Pagination />
                  </div>
                </Stack>
              </InstantSearch>
            </div>

            {/* <InputGroup>
              <Input
                borderBottomColor="#FF9966"
                variant="flushed"
                placeholder="Search"
                fontSize="4xl"
                focusBorderColor="orange.500"
                paddingBottom="3"
                value={form.search}
                onChange={(e) => setForm({ ...form, search: e.target.value })}
              />
              <InputRightElement
                children={<MdClose color="white" size={24} />}
              />
            </InputGroup>

            <SimpleGrid
              columns={{ base: 1, xl: 4, md: 2 }}
              width="100%"
              spacing={10}
              mt="4"
            >
              <VStack spacing={3} width="100%" justifyContent="flex-start">
                <Text
                  textAlign="left"
                  width="100%"
                  fontSize="xl"
                  color="white"
                  my="4"
                >
                  Price
                </Text>
                <RangeSlider
                  aria-label={["min", "max"]}
                  min={0}
                  max={500}
                  defaultValue={form.range}
                  onChange={(n) => {
                    setForm({ ...form, range: n });
                  }}
                  value={form.range}
                >
                  <RangeSliderTrack bg="red.100">
                    <RangeSliderFilledTrack bg="tomato" />
                  </RangeSliderTrack>
                  <RangeSliderThumb boxSize={4} index={0}>
                    <Box color="tomato" as={MdGraphicEq} />
                  </RangeSliderThumb>
                  <RangeSliderThumb boxSize={4} index={1}>
                    <Box color="tomato" as={MdGraphicEq} />
                  </RangeSliderThumb>
                </RangeSlider>
                <HStack
                  spacing={3}
                  alignItems="center"
                  justifyContent="flex-start"
                  alignSelf="flex-start"
                  my="4"
                >
                  <Text
                    color="white"
                    fontSize="lg"
                    textAlign="center"
                    width="14"
                    paddingBottom="2"
                    borderBottom="1px solid gray"
                  >
                    {form.range[0] === 0 ? "Free" : "$" + form.range[0]}
                  </Text>
                  <Text
                    color="white"
                    fontSize="lg"
                    textAlign="center"
                    width="14"
                    paddingBottom="2"
                    borderBottom="1px solid gray"
                  >
                    {form.range[1] === 500 ? "Max" : "$" + form.range[1]}
                  </Text>
                </HStack>
              </VStack>
              <VStack spacing={3} width="100%" justifyContent="flex-start">
                <Text
                  textAlign="left"
                  width="100%"
                  fontSize="xl"
                  color="white"
                  my="4"
                >
                  Location
                </Text>
                <Autocomplete
                  className="autofill"
                  apiKey={GOOGLE_MAP_API_KEY}
                  placeholder="Find Place"
                  onPlaceSelected={(place) => {
                    console.log(place);
                  }}
                />
              </VStack>

              <VStack spacing={3} width="100%" justifyContent="flex-start">
                <Text
                  textAlign="left"
                  width="100%"
                  fontSize="xl"
                  color="white"
                  my="4"
                >
                  Date
                </Text>
                <DateRangePicker
                  style={{ width: "100%" }}
                  calendars={1}
                  value={form.date}
                  onChange={(newValue) => setForm({ ...form, date: newValue })}
                  renderInput={(startProps, endProps) => {
                    return (
                      <React.Fragment style={{ width: "100%" }}>
                        <VStack spacing={8}>
                          <InputGroup style={{ width: "100%" }}>
                            <Input
                              borderBottomColor="#FF9966"
                              variant="flushed"
                              placeholder="Start Date"
                              color="white"
                              width="100%"
                              fontSize="xl"
                              focusBorderColor="orange.500"
                              paddingBottom="3"
                              onFocus={startProps.inputProps.onFocus}
                              onBlur={startProps.inputProps.onBlur}
                              value={
                                form.date[0] &&
                                moment(form.date[0]).format("DD/MM/YYYY")
                              }
                              onChange={startProps.inputProps.onChange}
                            />
                           <InputRightElement
                            children={<MdClose color="white" size={24} />}
                          /> 
                          </InputGroup>
                          <InputGroup>
                            <Input
                              borderBottomColor="#FF9966"
                              variant="flushed"
                              placeholder="End Date"
                              color="white"
                              width="100%"
                              fontSize="xl"
                              focusBorderColor="orange.500"
                              paddingBottom="3"
                              onFocus={endProps.inputProps.onFocus}
                              onBlur={endProps.inputProps.onBlur}
                              value={
                                form.date[1] &&
                                moment(form.date[1]).format("DD/MM/YYYY")
                              }
                              onChange={endProps.inputProps.onChange}
                            />
                             <InputRightElement
                            children={<MdClose color="white" size={24} />}
                          /> 
                          </InputGroup>
                        </VStack>
                      </React.Fragment>
                    );
                  }}
                />
              </VStack>

              <VStack spacing={3} justifyContent="flex-start">
                <Text
                  textAlign="left"
                  width="100%"
                  fontSize="xl"
                  color="white"
                  my="4"
                >
                  Category
                </Text>
                <Select
                  value={form.category}
                  onChange={handleChange}
                  options={category}
                  styles={colourStyles}
                  placeholder="Select a category"
                />
              </VStack>
            </SimpleGrid>
           */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default NavBar;
