import { HStack, Text } from "@chakra-ui/layout";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import XsCard from "../../../components/card/Event/XsCard";
import { GOOGLE_MAP_API_KEY } from "../../../config";
import { WARRING } from "../../../store/actions/message";

const Nearby = () => {
  const [location, setLocation] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const nearby = useSelector((state) => state.Event.nearby);
  const dispatch = useDispatch();
  const mapRef = createRef();
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    } else {
      dispatch(WARRING("Location is Not Available!"));
    }
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });
  const containerStyle = {
    height: "300px",
    width: "100%",
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    map.zoom = 14;
    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  return (
    <div>
      <div style={{ marginBlock: 12 }}>
        <HStack>
          <div
            style={{
              overflow: "auto",
              height: 300,
              width: nearby.length === 0 ? "100%" : "30%",
            }}
          >
            {nearby.length === 0 ? (
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.05)",
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 300,
                }}
              >
                <img
                  src="/images/emoji.png"
                  alt="No Event"
                  height={100}
                  width={100}
                />
                <Text
                  my="2"
                  fontFamily="gilroy-bold"
                  fontSize="xl"
                  color="#fb4e58"
                >
                  No Nearby Events
                </Text>
              </div>
            ) : (
              nearby.map((events, i) => (
                <XsCard
                  key={i}
                  data={events}
                  handleDetails={() => {
                    setLocation({
                      lat: events.latitude,
                      lng: events.longitude,
                    });
                    map.zoom = 14;
                  }}
                />
              ))
            )}
          </div>
          <div style={{ flexGrow: 1 }}>
            {isLoaded
              ? nearby.length > 0 && (
                  <GoogleMap
                    ref={mapRef}
                    mapContainerStyle={containerStyle}
                    center={location}
                    zoom={14}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{
                      // fullscreenControl: false,
                      // zoomControl: false,
                      // mapTypeControl: false,
                      streetViewControl: false,
                    }}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker
                      icon="/images/currentLocation.svg"
                      position={currentLocation}
                    />
                    {nearby.map((events, i) => (
                      <Marker
                        key={i}
                        icon="/images/eventLocation.svg"
                        position={{
                          lat: events.latitude,
                          lng: events.longitude,
                        }}
                      />
                    ))}
                  </GoogleMap>
                )
              : null}
          </div>
        </HStack>
      </div>
    </div>
  );
};

export default Nearby;
