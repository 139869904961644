import { Text } from "@chakra-ui/layout";
import { Zoom } from "@material-ui/core";
import React, { useEffect, useState } from "react";
const SplashScreen = () => {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  }, []);
  return (
    <div
      className="SplashScreen"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
      }}
    >
      <Zoom in={isOpen} style={{ display: !isOpen && "none" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/fire.gif"}
          alt="Fire"
          width={230}
          height={230}
        />
      </Zoom>
      <Zoom in={!isOpen} style={{ display: isOpen && "none" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo2.svg"}
          alt="Fire"
          width={230}
          height={230}
        />
      </Zoom>
      <Text
        fontSize={{ base: "md", xl: "3xl", lg: "2xl", md: "xl", sm: "lg" }}
        mt="4"
        color="white"
      >
        {isOpen ? "Loading Tempo Events" : "Welcome To Tempo"}{" "}
        {isOpen && (
          <span className="ellipsis-anim">
            <span style={{ fontSize: "2.25rem" }}>.</span>
            <span style={{ fontSize: "2.25rem" }}>.</span>
            <span style={{ fontSize: "2.25rem" }}>.</span>
          </span>
        )}
      </Text>
    </div>
  );
};

export default SplashScreen;
