import { HStack, Text } from "@chakra-ui/layout";
import React from "react";
import Surface from "../../common/Surface";
import moment from "moment";
import { MdLocationOn } from "react-icons/md";
const SmCard = ({ data, handleDetails }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={handleDetails}>
      <Surface p="4" width="100%">
        <HStack spacing={4} alignItems="flex-start">
          <img
            src={data.artwork}
            style={{
              width: 100,
              height: 120,
              borderRadius: 7,
              objectFit: "cover",
            }}
            alt="Event"
          />
          <div>
            <Text color="#FF5E62" style={{ fontFamily: "gilroy-medium" }}>
              {moment(data.startTime).format("DD MMM YYYY")}
            </Text>
            <Text
              fontSize={{ base: "xs", sm: "lg" }}
              width={{ base: "70%", sm: "100%" }}
              noOfLines={2}
              mt="2"
              style={{ fontFamily: "gilroy-medium" }}
            >
              {data.title}
            </Text>
            <HStack spacing={1} alignItems="center" my={3}>
              <MdLocationOn fontSize={14} />
              <Text
                fontSize="xs"
                color="#72757A"
                noOfLines={1}
                width={{ base: "75%", sm: "100%" }}
                style={{
                  fontFamily: "gilroy-medium",
                }}
              >
                {data.location}
              </Text>
            </HStack>
          </div>
        </HStack>
      </Surface>
    </div>
  );
};

export default SmCard;
