import { Center, Divider, SimpleGrid, Text } from "@chakra-ui/layout";
import React from "react";
import Surface from "../../../components/common/Surface";

export default function Ticket({ data, promoDetail, tax = false, collectSalesTax= false, salesTaxPercentage=5}) {
  const totalQuantity = () => {
    let total = 0;
    data.map((item) => (total += item.quantity));
    return total;
  };

  const totalPrice = () => {
    let total = 0;
    data.map((item) => (total += item.quantity * item.price));
    return total - (promoDetail?.amount_off ?? 0);
  };

  const TOTAL = (price, tax, salesTax) => {
    let total = price + tax + salesTax;
    return total === 0 ? "Free" : "$ " + total.toFixed(2);
  };

  const showTable = () => {
    let a = data.filter((item) => item.quantity !== 0);

    if (a.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  function calculateSalesTax(subtotal, totalQuantityOfTickets) {
    return parseFloat((subtotal * 0.07 + totalQuantityOfTickets).toFixed(2));
  }
    function calculateActualSalesTax(subtotal, salesTaxPercentage) {
    if (!collectSalesTax) {
      return 0
    }
    return parseFloat((subtotal * (salesTaxPercentage/100)))
  }

  return (
    <div>
      {showTable() && (
        <Surface p="6" my="6">
          <SimpleGrid columns={3} py="6">
            <Center>
              <Text
                fontSize={{ base: "sm", sm: "lg" }}
                textAlign="center"
                fontWeight="bold">
                Tickets
              </Text>
            </Center>
            <Center>
              <Text
                fontSize={{ base: "sm", sm: "lg" }}
                textAlign="center"
                fontWeight="bold">
                Quantity
              </Text>
            </Center>
            <Center>
              <Text
                fontSize={{ base: "sm", sm: "lg" }}
                textAlign="center"
                fontWeight="bold">
                Subtotal
              </Text>
            </Center>
          </SimpleGrid>
          {data.map(
            (item, i) =>
              item.quantity !== 0 && (
                <>
                  <SimpleGrid columns={3} my="2">
                    <Center>
                      <Text
                        fontSize="md"
                        textAlign="center"
                        fontWeight="medium">
                        {item.label}
                      </Text>
                    </Center>
                    <Center>
                      <Text
                        fontSize="md"
                        textAlign="center"
                        fontWeight="medium">
                        x{item.quantity}
                      </Text>
                    </Center>
                    <Center>
                      <Text
                        fontSize="md"
                        textAlign="center"
                        fontWeight="medium">
                        {item.price === 0
                          ? "Free"
                          : "$ " + item.price * item.quantity}
                      </Text>
                    </Center>
                  </SimpleGrid>
                  <Divider />
                </>
              ),
          )}
          {tax && (
            <>
              <SimpleGrid columns={3} my="2">
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    Fee
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    {"-"}
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    ${calculateSalesTax(totalPrice(), totalQuantity()).toFixed(2)}
                  </Text>
                </Center>
              </SimpleGrid>
              <Divider />
            </>
          )}
          <Divider />
                    {collectSalesTax && (
            <>
              <SimpleGrid columns={3} my="2">
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    Sales Tax
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    {"-"}
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="md" textAlign="center" fontWeight="medium">
                    ${calculateActualSalesTax(totalPrice(), salesTaxPercentage).toFixed(2)}
                  </Text>
                </Center>
              </SimpleGrid>
              <Divider />
            </>
          )}
          <Divider />
          <SimpleGrid columns={3} my="4">
            <Center>
              <Text fontSize="xl" textAlign="center" fontWeight="bold">
                Total
              </Text>
            </Center>
            <Center>
              <Text fontSize="xl" textAlign="center" fontWeight="bold">
                x{totalQuantity()}
              </Text>
            </Center>
            <Center>
              <Text fontSize="xl" textAlign="center" fontWeight="bold">
                {TOTAL(
                  totalPrice(),
                  tax ? calculateSalesTax(totalPrice(), totalQuantity()) : 0,
                  collectSalesTax ? calculateActualSalesTax(totalPrice(), salesTaxPercentage) : 0
                )}
              </Text>
            </Center>
          </SimpleGrid>
        </Surface>
      )}
    </div>
  );
}
