import ReactGA from "react-ga";

// for initializing the google analytics connection
export const initGA = () => {
  const trackingId = "G-T20J3D9FFY";
  ReactGA.initialize(trackingId, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
};

// for page view
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// for custom events
export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};
