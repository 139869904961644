import { Box, HStack, SimpleGrid, Stack, Text } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import React from "react";
import Surface from "../../../components/common/Surface";
import "./style.css";
const Mobile = () => {
  const [is786] = useMediaQuery("(min-width: 786px)");
  return (
    <div>
      <div id="MobileApp" style={{ marginBottom: 25 }}></div>
      <Surface>
        <SimpleGrid columns={is786 ? 2 : 1} spacing={10} p="8">
          <div>
            <Text
              fontSize={{ base: "lg", sm: "4xl" }}
              style={{ fontFamily: "gilroy-bold", lineHeight: "45px" }}
            >
              Use Tempo on mobile to access more features like...
            </Text>
            <Box height="20px"></Box>
            <Text fontSize="xl" style={{ fontFamily: "gilroy-semi-bold" }}>
              Create Events
            </Text>
            <Text
              fontSize="sm"
              color="#72757A"
              style={{ fontFamily: "gilroy-medium" }}
            >
              Create events and sell tickets of your <br />
              events
            </Text>
            <Box height="20px"></Box>
            <Text fontSize="xl" style={{ fontFamily: "gilroy-semi-bold" }}>
              QR Code Check-In
            </Text>
            <Text
              fontSize="sm"
              color="#72757A"
              style={{ fontFamily: "gilroy-medium" }}
            >
              Check-In on events just by scanning your <br /> ticket at Tempo
            </Text>
            <Box height="20px"></Box>
            <Text fontSize="xl" style={{ fontFamily: "gilroy-semi-bold" }}>
              Receive Notifications
            </Text>
            <Text
              fontSize="sm"
              color="#72757A"
              style={{ fontFamily: "gilroy-medium" }}
            >
              Mange your profile and receive notifications <br /> regarding new
              events and more...
            </Text>
            <Box height="20px"></Box>
            <Stack direction={{ base: "column", sm: "row" }} ml="-3">
              <img
                src="/images/google-play-badge.png"
                className="android-download"
                alt="Android"
              />
              <img
                src="/images/iOS Download Sticker.png"
                className="iphone-download"
                alt="Iphone"
              />
            </Stack>
          </div>
          {is786 && (
            <HStack
              className="cell-phone"
              alignItems="center"
              justifyContent="flex-end"
              width="100%"
            >
              {/* <img
                src="/images/Group 414.png"
                className="android"
                alt="Android"
              /> */}
              <img
                src="/images/mobile screenshots.png"
                alt="Iphone"
                className="iphone"
              />
            </HStack>
          )}
        </SimpleGrid>
      </Surface>
    </div>
  );
};

export default Mobile;
