import api from "../../api";

export const fetchDetails = async (eventId, paymentId) => {
  try {
    const { data } = await api.get("/payment/success", {
      headers: {
        Event: eventId,
        Payment: paymentId,
      },
    });
    return data;
  } catch (err) {
    throw err.response.data;
  }
};

export const resendEmail = async (eventId, paymentId) => {
  try {
    const { data } = await api.post("/payment/resend-email", {
      eventId,
      paymentId,
    });
    return data;
  } catch (err) {
    throw err.response.data;
  }
}


export const generateIOSTicket = async (checkInId) => {
  console.log(checkInId, "the id");
  try {
    const { data } = await api.post("/signer/generate-ios-ticket", {
      checkInIds: [checkInId],
    });
    console.log(data);
    return data.passes[checkInId];
  } catch (err) {
    throw err.response.data;
  }
};

export const generateGoogleTicket = async (checkInId) => {
  try {
    const { data } = await api.post("/signer/generate-google-ticket", {
      checkInIds: [checkInId],
    });
    console.log(data);
    return data.passes[checkInId];
  } catch (err) {
    throw err.response.data;
  }
};
