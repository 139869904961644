import { CssBaseline } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./App.css";
import Footer from "./components/common/Footer";
import NavBar from "./components/common/NavBar";
import SplashScreen from "./components/SplashScreen";
import Checkout from "./pages/Checkout";
import EventDetails from "./pages/EventDetails";
import Events from "./pages/Events";
import Landing from "./pages/Landing";
import NotFound from "./pages/NotFound";
import PaymentSuccess from "./pages/PaymentSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermAndConditions from "./pages/TermsAndConditions";
import { NO_MESSAGE } from "./store/actions/message";
import GoogleAnalytics from "./helpers/GoogleAnalytics";
// import firebase from "firebase";

// const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

function App() {
  const msg = useSelector((state) => state.Message.msg);
  const dispatch = useDispatch();
  const [state, setState] = useState(true);

  useEffect(() => {
    if (msg) {
      toast[msg.status](msg.title, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // if (msg.status === "success") {
      // } else if (msg.status === "error") {
      //   toast.error(msg.title, {
      //     position: "bottom-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // } else if (msg.status === "info") {
      //   toast.info(msg.title, {
      //     position: "bottom-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // } else if (msg.status === "warning") {
      //   toast.warn(msg.title, {
      //     position: "bottom-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }
      dispatch(NO_MESSAGE());
    }
  }, [msg]);
  useEffect(() => {
    setTimeout(() => {
      setState(false);
    }, 2000);
  }, [state]);
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);
  console.log("Tempo Events");
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={state}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        {state ? (
          <SplashScreen />
        ) : (
          <Router>
            <div className="App">
              <GoogleAnalytics />
              <CssBaseline />
              <ToastContainer />
              <NavBar />
              <Switch>
                <Route
                  path="/terms-and-conditions"
                  exact
                  component={TermAndConditions}
                />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/notFound" exact component={NotFound} />
                <Route path="/event/checkout" exact component={Checkout} />
                <Route
                  path="/event/:eventId/payment/:paymentId/success/"
                  exact
                  component={PaymentSuccess}
                />
                <Route path="/events/:events" exact component={Events} />
                <Route path="/:id" exact component={EventDetails} />
                <Route path="/" component={Landing} />
                {/* <Redirect from="*" to="/notFound" /> */}
              </Switch>
              <Footer />
            </div>
          </Router>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
}

export default App;
