import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaClock, FaTwitter, FaUserAlt } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { IoIosMail } from "react-icons/io";
import { IoCalendar, IoLocationSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/Loading";
import { ERROR, SUCCESS } from "../../store/actions/message";
import Map from "../EventDetails/components/Map";
import PaymentExpired from "../PaymentExpired";
import { fetchDetails,  resendEmail } from "./service";
import { generateGoogleTicket, generateIOSTicket } from "./service";
import ATG from "./atg.svg";
import ATA from "./ata.svg";

const status = {
  pending: "#CC54C0",
  "checked-in": "#57CC54",
};

const PaymentSuccess = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [is425] = useMediaQuery("(max-width: 425px)");
  const { eventId, paymentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [currentQR, setCurrentQR] = useState(null);
  const [isResending, setIsResending] = useState(false);


  const resendEmailFunc = async () => {
    try {
      setIsResending(true);
      const { status, data } = await resendEmail(eventId, paymentId);
      if (status) {
        dispatch(SUCCESS("Email sent successfully"));
        setIsResending(false);
      }
    } catch (err) {
      dispatch(ERROR("Unable to send email"));
      console.log(err);
    }
  };

  const getDetails = async () => {
    try {
      const { status, data } = await fetchDetails(eventId, paymentId);
      if (status) {
        console.log(data);
        setData(data);
        setLoading(false);
      }
    } catch (err) {
      dispatch(ERROR("Purchase was successful, but some error occured."));
      console.log(err);
      if (err.data) {
        const { data } = err;
        if (data.tickets.length && data.event && data.purchase) {
          setLoading(false);
          setData(data);
        }
      }
    }
  };
  console.log(data, "saledata");

  async function addPassIOS(item) {
    try {
      setLoading(true);
      console.log(item.id);
      // check if pass exists on checkInId
      if (item.iosPass === undefined) {
        generateIOSTicket(item.id).then((url) => {
          setLoading(false);

          console.log(url, "url");
          window.location.assign(url);
          // window.open(url, "_blank", "noopener,noreferrer");
        });
      } else {
        setLoading(false);
        window.location.assign(item.iosPass);
        // window.open(item.iosPass, "_blank", "noopener,noreferrer");
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function addPassAndroid(item) {
    try {
      setLoading(true);
      console.log(item.id);
      var windowReference = window.open();
      // check if pass exists on checkInId
      if (item.googlePass === undefined) {
        generateGoogleTicket(item.id).then((url) => {
          setLoading(false);
          // console.log(url, "url");
          windowReference.location = url;
        });
      } else {
        setLoading(false);
        windowReference.location = item.googlePass;
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getDetails();
  }, []);

  if (loading) {
    return <Loading title={"Loading your payment"} />;
  }

  if (!data) {
    return <PaymentExpired />;
  }

  return (
    <div style={{ marginInline: "7%", flex: 1 }}>
      <Divider mt="4" borderWidth={0.5} color="rgba(255, 255, 255, 0.25)" />
      <Stack mt="4" direction={{ base: "column", lg: "row" }} spacing={8}>
        <div style={{ flex: 1 }}>
          <Text fontFamily="gilroy-bold" fontSize={24}>
            Purchase information
          </Text>
          <HStack spacing={3} mt="4" alignItems="center">
            <FaUserAlt fontSize={18} />
            <Text
              fontFamily="gilroy-regular"
              fontSize={{ base: 18, lg: 14 }}
              paddingTop={1.5}
            >
              {data.purchase.name ? data.purchase.name : ""}
            </Text>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="center">
            <IoIosMail fontSize={18} />
            <Text
              fontFamily="gilroy-regular"
              fontSize={{ base: 18, lg: 14 }}
              paddingTop={1.5}
            >
              {data.purchase.email ? data.purchase.email : ""}


            </Text>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="center">
            <HiOutlineTicket fontSize={18} />
            <Text
              fontFamily="gilroy-regular"
              fontSize={{ base: 18, lg: 14 }}
              paddingTop={1.5}
            >
              {data.purchase.totalTicketsCount
                ? `${data.purchase.totalTicketsCount} tickets purchased`
                : ""}
            </Text>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="flex-start">
            <FaClock fontSize={18} style={{ paddingTop: 2 }} />
            <div>
              <Text
                fontFamily="gilroy-medium"
                color="rgba(0, 0, 0, 0.5)"
                fontSize={{ base: 18, lg: 14 }}
              >
                Tickets purchased on
              </Text>
              <Text
                fontFamily="gilroy-medium"
                fontSize={{ base: 14, sm: 18, lg: 12 }}
                paddingTop={1.5}
              >
                {data.purchase.purchasedAt
                  ? moment(data.purchase.purchasedAt.seconds).format("LLLL")
                  : ""}
              </Text>
            </div>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="center">
            <Text fontFamily="gilroy-bold" flex={1} fontSize={18}>
              Tickets information
            </Text>
            <Text fontFamily="gilroy-bold" fontSize={18}>
              ({data.tickets.length})
            </Text>
          </HStack>
          <Text
            fontFamily="gilroy-medium"
            color="rgba(0, 0, 0, 0.5)"
            fontSize={14}
          >
            List of tickets you’ve purchased
          </Text>
          <Box mt="4">
            {data.tickets.map((item, i) => (
              <React.Fragment key={String(i)}>
                <HStack spacing={0} my="2" alignItems="flex-start">
                  <div style={{ paddingTop: 12, flex: 1 }}>
                    <HStack alignItems={"flex-start"}>
                      {/* {is425 && (
                        <Box
                          flex={0.5}
                          shadow="md"
                          style={{
                            objectFit: "contain",
                            width: "auto",
                            height: "auto",
                            borderRadius: 10,
                            padding: 7,
                            backgroundColor: "white",
                          }}>
                          <img
                            src={item.qr}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                            }}
                            alt="Event QR"
                          />
                        </Box>
                      )} */}
                      <Stack
                        flex={1}
                        spacing={0}
                        direction={{ base: "column", md: "row" }}
                      >
                        <div style={{ flex: 1 }}>
                          <Text fontFamily="gilroy-bold" fontSize={"2xl"}>
                            {item.label}
                          </Text>
                          <Text
                            fontFamily="gilroy-bold"
                            textTransform={"uppercase"}
                            fontSize={"small"}
                            color={"gray.500"}
                            mt="2"
                          >
                            Description
                          </Text>
                          <Text
                            fontFamily="gilroy-regular"
                            mt={"1"}
                            fontSize={"small"}
                          >
                            {item.description.split(/\n/).map((line) => (
                              <>
                                {line}
                                <br />
                              </>
                            ))}
                          </Text>
                        </div>
                        {/* <div style={{ flex: 1 }}>
                          <Stack
                            direction={{ base: "row", sm: "column" }}
                            alignItems="flex-start"
                            mt={{ base: "4", sm: "0" }}>
                            <Text
                              fontFamily="gilroy-bold"
                              fontSize={{ base: 12, sm: 14 }}
                              textAlign={{ base: "left", sm: "right" }}
                              width="full">
                              Check-in Status
                            </Text>
                            <Text
                              fontFamily="gilroy-bold"
                              color={
                                status[
                                  item.isCheckedIn ? "checked-in" : "pending"
                                ]
                              }
                              fontSize={{ base: 11, sm: 14 }}
                              textAlign="right"
                              width="full"
                              mt="2">
                              {item.isCheckedIn ? "CHECKED-IN" : "PENDING"}
                            </Text>
                          </Stack>
                        </div> */}
                      </Stack>
                    </HStack>

                    <HStack flex={1} spacing={3} mt="2" alignItems="center">
                      <div style={{ flex: 1 }}>
                        <Text
                          fontFamily="gilroy-medium"
                          color="rgba(0, 0, 0, 0.5)"
                          fontSize={12}
                        >
                          Per ticket cost
                        </Text>
                        <Text fontFamily="gilroy-medium" fontSize={12}>
                          {item.price ? `$${item.price}` : "FREE"}
                        </Text>
                      </div>
                      {/* <Divider
                        orientation="vertical"
                        borderWidth={0.5}
                        height="8"
                        color="rgba(255, 255, 255, 0.25)"
                      /> */}
                      {/* <div style={{ flex: 1 }}>
                        <Text
                          fontFamily="gilroy-medium"
                          color="rgba(0, 0, 0, 0.5)"
                          fontSize={12}>
                          Quantity
                        </Text>
                        <Text fontFamily="gilroy-medium" fontSize={12}>
                          x6
                        </Text>
                      </div> 
                      <Divider
                        orientation="vertical"
                        borderWidth={0.5}
                        height="8"
                        color="rgba(255, 255, 255, 0.25)"
                      /> */}
                      <div>
                        <Text
                          fontFamily="gilroy-medium"
                          color="rgba(0, 0, 0, 0.5)"
                          textAlign="right"
                          fontSize={12}
                          mt="2"
                        >
                          Subtotal
                        </Text>
                        <Text
                          fontFamily="gilroy-medium"
                          textAlign="right"
                          fontSize={12}
                        >
                          {item.total ? `$${item.total}` : "FREE"}
                        </Text>
                      </div>
                    </HStack>
                  </div>
                </HStack>
                {i !== data.tickets.length - 1 && (
                  <Divider
                    mt="4"
                    mb="4"
                    borderWidth={0.5}
                    color="rgba(255, 255, 255, 0.25)"
                  />
                )}
                <Text fontFamily="gilroy-bold" fontSize={"larger"}>
                  Ticket Data
                </Text>

                <SimpleGrid
                  columns={{ base: 1, xl: 1, lg: 1, sm: 1 }}
                  mt={4}
                  style={{ flexWrap: "wrap" }}
                  pb={4}
                >
                  {item.checkInList.map((checkInItem, index) => {
                    return (
                      <HStack
                        style={{ flexWrap: "wrap" }}
                        spacing={2}
                        // justifyContent="flex-start"
                        alignItems="center"
                        mb={5}
                        // flexDirection="column"
                        flexDirection={{
                          base: "column",
                          // md: "row",

                          lg: "row",
                          xl: "row",
                          // "2xl": "column",
                        }}
                      >
                        <Box
                          mb={4}
                          shadow={"sm"}
                          mr={{ base: null, lg: 4 }}
                          style={{
                            borderRadius: 10,
                            alignItems: "center",

                            padding: 2,
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                            backgroundColor: "white",
                          }}
                        >
                          <Image
                            _hover={{ shadow: "md" }}
                            transition={"ease"}
                            src={checkInItem.qr}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                            }}
                            alt="Event QR"
                          />
                        </Box>
                        <Box
                          alignSelf="center"
                          // alignItems="flex-end"
                          display="inline-flex"
                          height={{ base: null, md: "100%" }}
                          flexDirection="column"
                          alignItems={{
                            base: "center",
                            sm: "center",
                            md: "center",
                            lg: "flex-start",
                            xl: "flex-start",
                          }}
                          justifyContent="space-around"
                        >
                          <Text
                            fontFamily="gilroy-bold"
                            mt={4}
                            // textAlign={"start"}
                            fontSize={"medium"}
                          >
                            Ticket {index + 1} | Status:{" "}
                            <Text
                              as="span"
                              fontFamily="gilroy-bold"
                              color={
                                status[
                                item.isCheckedIn ? "checked-in" : "pending"
                                ]
                              }
                              alignSelf="flex-end"
                              fontSize={{ base: 12, sm: 14 }}
                              textAlign={"start"}
                              my="1"
                            >
                              {item.isCheckedIn ? "CHECKED-IN" : "PENDING"}
                            </Text>
                          </Text>

                          <Text
                            onClick={() => {
                              setCurrentQR({
                                ticketName: item.label,
                                qr: checkInItem.qr,
                              });
                              onOpen();
                            }}
                            fontFamily="gilroy-bold"
                            color={"gray.500"}
                            _hover={{ cursor: "pointer" }}
                            fontSize={{ base: 12, sm: 12 }}
                            textAlign={"start"}
                            mb={"3"}
                          >
                            VIEW QR
                          </Text>
                          <Box
                            display="flex"
                            // height="100%"
                            height={{ base: null, lg: "100%" }}
                            flexDirection={{
                              xs: "column",
                              sm: "column",
                              md: "column",
                            }}
                            pt={3}
                            alignSelf="flex-end"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Image
                              _hover={{ shadow: "md" }}
                              transition={"ease"}
                              // width={{ md: "150px" }}
                              src={ATA}
                              onClick={() => {
                                addPassIOS(checkInItem);
                              }}
                              style={{
                                objectFit: "contain",
                                width: "90%",
                                height: "3em",
                              }}
                              mr={3}
                              alt="Add to Apple Wallet"
                            />
                            <Image
                              _hover={{ shadow: "md" }}
                              transition={"ease"}
                              // width={{ md: "150px" }}
                              // height={{ md: "150px" }}
                              src={ATG}
                              onClick={() => {
                                addPassAndroid(checkInItem);
                              }}
                              style={{
                                objectFit: "contain",
                                width: "90%",
                                height: "3em",
                              }}
                              alt="Add to Google Wallet"
                            />
                          </Box>
                        </Box>
                      </HStack>
                    );
                  })}
                </SimpleGrid>
              </React.Fragment>
            ))}
          </Box>
          <Divider mt="4" borderWidth={0.5} color="rgba(255, 255, 255, 0.25)" />
          <Stack
            direction={{ base: "column", sm: "row" }}
            mt="6"
            alignItems="flex-start"
            spacing={4}
          >
            <Text fontFamily="gilroy-bold" fontSize={14}>
              TICKETS TOTAL
            </Text>
            <Stack spacing={6} w="full" direction="column">
              <HStack w="full" spacing={3} alignItems="center">
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={12}
                    >
                      Fee
                    </Text>
                    <Text fontFamily="gilroy-medium" fontSize={12}>
                      {data.purchase.salesTax
                        ? `$${data.purchase.salesTax}`
                        : "N/A"}
                    </Text>
                  </div>
                  <div>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={12}
                    >
                      Sales Tax
                    </Text>
                    <Text fontFamily="gilroy-medium" fontSize={12}>
                      {data.purchase.actualTax
                        ? `$${data.purchase.actualTax.toFixed(2)}`
                        : "N/A"}
                    </Text>
                  </div>
                </div>
                <Divider
                  orientation="vertical"
                  borderWidth={0.5}
                  height="8"
                  color="rgba(255, 255, 255, 0.25)"
                />
                <div style={{ flex: 1 }}>
                  <Text
                    fontFamily="gilroy-medium"
                    color="rgba(0, 0, 0, 0.5)"
                    fontSize={12}
                  >
                    Total Quantity
                  </Text>
                  <Text fontFamily="gilroy-medium" fontSize={12}>
                    x{data.purchase.totalTicketsCount}
                  </Text>
                </div>
                <Divider
                  orientation="vertical"
                  borderWidth={0.5}
                  height="8"
                  color="rgba(255, 255, 255, 0.25)"
                />
                <div style={{ flex: 1 }}>
                  <Text
                    fontFamily="gilroy-medium"
                    color="rgba(0, 0, 0, 0.5)"
                    textAlign="right"
                    fontWeight={"bold"}
                    fontSize={12}
                  >
                    Total
                  </Text>
                  <Text
                    fontFamily="gilroy-medium"
                    textAlign="right"
                    fontSize={12}
                  >
                    {data.purchase.totalAmount
                      ? `$${data.purchase.totalAmount.toFixed(2)}`
                      : "Free"}
                  </Text>
                </div>
              </HStack>{" "}
              {data.purchase.promocode && (
                <HStack w="full" spacing={3} alignItems="center">
                  <div style={{ flex: 0.95 }}>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={12}
                    >
                      Promo Code
                    </Text>
                    <Text fontFamily="gilroy-medium" fontSize={10}>
                      {data.purchase.promocode?.code}
                    </Text>
                  </div>
                  <Divider
                    orientation="vertical"
                    borderWidth={0.5}
                    height="8"
                    color="rgba(255, 255, 255, 0.25)"
                  />
                  <div style={{ flex: 2 }}>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={12}
                    >
                      Promo Code Discount
                    </Text>
                    <Text fontFamily="gilroy-medium" fontSize={12}>
                      ${data.purchase.promocodeAmount}
                    </Text>
                  </div>
                  <div style={{ flex: 0 }}></div>
                </HStack>
              )}
            </Stack>
          </Stack>
        </div>
        <div style={{ flex: 1 }}>
          <Text fontFamily="gilroy-bold" fontSize={24}>
            Event Information
          </Text>
          <img
            src={data.event.artwork ? data.event.artwork : ""}
            style={{
              width: "100%",
              height: 200,
              objectFit: "cover",
              borderRadius: 12,
              marginTop: 12,
            }}
            alt="Event Name"
          />
          <Text fontFamily="gilroy-bold" mt="4" fontSize={24}>
            {data.event.title ? data.event.title : ""}
          </Text>
          <Stack direction={{ base: "column", sm: "row" }} spacing={8}>
            <div style={{ flex: 1 }}>
              <HStack spacing={3} mt="2" alignItems="center">
                <IoCalendar fontSize={18} />
                <Text
                  fontFamily="gilroy-regular"
                  color="#FF6B66"
                  fontSize={{ base: 18, lg: 14 }}
                  paddingTop={1.5}
                >
                  {data.event.time
                    ? moment(data.event.time).format("D MMM YYYY")
                    : ""}
                </Text>
              </HStack>
              <HStack spacing={3} mt="2" alignItems="center">
                <FiExternalLink fontSize={18} />
                <Text
                  fontFamily="gilroy-regular"
                  fontSize={{ base: 18, lg: 14 }}
                  paddingTop={1.5}
                >
                  <a
                    href={`https://tempo.live/${data.event.eventUrl}`}
                  >{`https://tempo.live/${data.event.eventUrl}`}</a>
                </Text>
              </HStack>
              <HStack spacing={3} mt="2" alignItems="center">
                <IoLocationSharp fontSize={18} />
                <Text
                  fontFamily="gilroy-regular"
                  fontSize={{ base: 18, lg: 12 }}
                  paddingTop={1.5}
                >
                  {data.event.location ? data.event.location : ""}
                </Text>
              </HStack>
            </div>
            <div style={{ flex: 1 }}>
              <HStack alignItems="center" mt="2">
                <HStack spacing={3} flex={1} alignItems="flex-start">
                  <FaClock fontSize={18} style={{ paddingTop: 2 }} />
                  <div>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={{ base: 18, lg: 13 }}
                    >
                      Start Time
                    </Text>
                    <Text
                      fontFamily="gilroy-medium"
                      fontSize={{ base: 18, lg: 14 }}
                      mt="2"
                    >
                      {data.event.startTime
                        ? moment(data.event.startTime).format("hh:mm A")
                        : ""}
                    </Text>
                  </div>
                </HStack>
                <HStack spacing={3} flex={1} alignItems="flex-start">
                  <FaClock fontSize={18} style={{ paddingTop: 2 }} />
                  <div>
                    <Text
                      fontFamily="gilroy-medium"
                      color="rgba(0, 0, 0, 0.5)"
                      fontSize={{ base: 18, lg: 14 }}
                    >
                      End Time
                    </Text>
                    <Text
                      fontFamily="gilroy-medium"
                      fontSize={{ base: 18, lg: 14 }}
                      mt="2"
                    >
                      {data.event.endTime
                        ? moment(data.event.endTime).format("hh:mm A")
                        : ""}
                    </Text>
                  </div>
                </HStack>
              </HStack>
            </div>
          </Stack>
          <Box></Box>
          <Map
            height="140px"
            eventLocation={{
              lng: data.event.longitude,
              lat: data.event.latitude,
            }}
          />
          <Divider mt="4" borderWidth={0.5} color="rgba(255, 255, 255, 0.25)" />
          <HStack spacing={3} mt="4" alignItems="center">
            <Text fontFamily="gilroy-bold" flex={1} fontSize={18}
              color='tomato'
            >

              Please check your spam folder if you don’t see the email in your inbox.
            </Text>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="center">
            <Text  fontFamily="gilroy-bold" flex={1} fontSize={18}>
              or
            </Text>
          </HStack>
          <HStack spacing={3} mt="4" alignItems="center" className="filterBar">
            <Text><Button isLoading={isResending} size="lg" className="inactive" onClick={resendEmailFunc}>Resend Email</Button></Text>
          </HStack>
          <HStack alignItems={"flex-start"} mt="6">
            <div style={{ flex: 1 }}>
              <Text fontSize={12} font="gilroy-regular">
                For support contact at
              </Text>
              <Text fontSize={12} font="gilroy-regular">
                support@tempo.live
              </Text>
            </div>
            <div>
              <Text fontSize={12} font="gilroy-regular" textAlign="right">
                Connect with Tempo
              </Text>
              <HStack
                alignItems="center"
                justifyContent={`flex-end`}
                spacing={4}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/tempoeventsapp/"
                >
                  <AiFillInstagram fontSize={28} color="#FF9966" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/tempoeventsapp"
                >
                  <FaTwitter fontSize={28} color="#FF9966" />
                </a>
              </HStack>
            </div>
          </HStack>
        </div>
      </Stack>
      {currentQR && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{currentQR.ticketName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Image
                src={currentQR.qr}
                style={{
                  objectFit: "contain",
                  width: "100%",
                }}
                alt="Event QR"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default PaymentSuccess;
