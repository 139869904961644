import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/layout";
import React from "react";

const headingSpacing = { marginTop: "30px", marginBottom: "30px" };
const textSpacing = { marginTop: "6px", marginBottom: "6px" };

const tableOfContents = [
  { title: "Personal Information We Collect", anchor: "#personal-information" },
  {
    title: "How We Use Your Personal Information",
    anchor: "#personal-information-usage",
  },
  {
    title: "How We Share your Personal Information",
    anchor: "#personal-information-share",
  },
  { title: "Your Choices", anchor: "#your-choice" },
  {
    title: "Other sites, mobile applications and services",
    anchor: "#other-services",
  },
  { title: "Security practices", anchor: "#security-practices" },
  {
    title: "International data transfers",
    anchor: "#international-data-transfers",
  },
  { title: "Children", anchor: "#children" },
  {
    title: "Changes to this Privacy Policy",
    anchor: "#privacy-policy-changes",
  },
  { title: "How to Contact Us", anchor: "#contact-us" },
  {
    title: "Your California Privacy Rights",
    anchor: "#california-privacy-rights",
  },
];

const providedInformation = [
  {
    heading: "Business and personal contact information, ",
    subtitle:
      "such as your first and last name, email and mailing addresses, phone number, professional title and company name.",
  },
  {
    heading: "Content you choose to upload to the Service, ",
    subtitle:
      "such as text, images, audio, and video, along with the metadata associated with the files you upload.",
  },
  {
    heading: "Profile information, ",
    subtitle:
      "such as your username and password that you may set to establish an online account with us, your photograph, interests, and preferences.",
  },
  {
    heading: "Registration information, ",
    subtitle:
      "such as information that may be related to a service, an account or an event you register for.",
  },
  {
    heading: "Feedback or correspondence, ",
    subtitle:
      "such as information you provide when you contact us with questions, feedback, or otherwise correspond with us online.",
  },
  {
    heading: "Demographic Information, ",
    subtitle:
      "such as your city, state, country of residence, postal code, and age.",
  },
  {
    heading: "Precise geolocation information, ",
    subtitle:
      "such as when you authorize our mobile application to access your location.",
  },
  {
    heading: "Transaction information, ",
    subtitle:
      "such as information about payments to and from you and other details of products or services you have purchased from us.",
  },
  {
    heading: "Usage information, ",
    subtitle:
      "such as information about how you use the Service and interact with us, including information associated with any content you upload to the websites or otherwise submit to us, and information you provide when you use any interactive features of the Service.",
  },
  {
    heading: "Marketing information, ",
    subtitle:
      "such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications",
  },
  {
    heading: "Other information, ",
    subtitle:
      "that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.",
  },
];

const personalInformtionUsage = [
  "provide, operate and improve the Service",
  "provide information about our products and services",
  "establish and maintain your user profile on the Service",
  "facilitate your to login to the Service via third-party identity and access management providers, such as Facebook",
  "enable security features of the Service, such as by sending you security codes via email or SMS, and  remembering devices from which you have previously logged in",
  "facilitate social features of the Service, such as by identifying and suggesting connections with other   users of the Service and providing chat or messaging functionality",
  "communicate with you about the Service, including by sending you announcements, updates, security   alerts, and support and administrative messages",
  "communicate with you about events or contests in which you participate",
  "understand your needs and interests, and personalize your experience with the Service and our communications",
  "provide support and maintenance for the Service",
  "to respond to your requests, questions and feedback",
];

export default function PrivacyPolicy() {
  return (
    <div style={{ marginInline: "7%", textAlign: "justify" }}>
      <div style={headingSpacing}>
        <section style={{ marginBottom: "12px" }}>
          <Heading>Privacy Policy</Heading>
          <Text style={textSpacing}>Effective as of 10/14/2021</Text>
          <Text style={textSpacing}>
            This “Privacy Policy” describes the privacy practices of Tempo Live
            Events LLC. and our subsidiaries and affiliates (collectively,
            “Tempo”, “we”, “us”, or “our”) in connection with the www.tempo.live
            website, the Tempo mobile application, and any other website or
            mobile application that we own or control and which posts or links
            to this Privacy Policy (collectively, the “Service”), and the rights
            and choices available to individuals with respect to their
            information. Tempo may provide additional or supplemental privacy
            policies to individuals for specific products or services that we
            offer at the time we collect personal information. These
            supplemental privacy policies will govern how we may process the
            information in the context of the specific product or service.
          </Text>
          <Text style={{ ...textSpacing, fontWeight: "bold" }}>
            Table of Contents
          </Text>
          <UnorderedList>
            {tableOfContents.map((item, i) => {
              return (
                <ListItem key={i}>
                  <a href={item.anchor}>
                    <strong>{item.title}</strong>
                  </a>
                </ListItem>
              );
            })}
          </UnorderedList>
        </section>

        <section style={{ marginBottom: "12px" }} id={"personal-information"}>
          <Heading fontSize={"2xl"}>Personal Information We Collect</Heading>
          <Text style={{ marginTop: "6px" }}>
            <strong>Information you provide to us.</strong> Personal information
            you provide to us through the Service or otherwise includes:
          </Text>
          <UnorderedList>
            {providedInformation.map((item, i) => {
              return (
                <ListItem key={i}>
                  <strong>{item.heading}</strong>
                  {item.subtitle}
                </ListItem>
              );
            })}
          </UnorderedList>
          <Text style={textSpacing}>
            <strong>Information we obtain from social media platforms.</strong>{" "}
            We may maintain pages for our Company on social media platforms,
            such as Facebook, LinkedIn, Twitter, Google, YouTube, Instagram, and
            other third party platforms. When you visit or interact with our
            pages on those platforms, the platform provider’s privacy policy
            will apply to your interactions and their collection, use and
            processing of your personal information. You or the platforms may
            provide us with information through the platform, and we will treat
            such information in accordance with this Privacy Policy.
          </Text>
          <Text style={textSpacing}>
            If you choose to login to the Service via a third-party platform or
            social media network, or otherwise connect your account on the
            third-party platform or network to your account through the Service,
            we may collect information from that platform or network. For
            example, this information may include your Facebook username, user
            ID, profile picture, cover photo, and networks to which you belong
            (e.g., school, workplace). You may also have the opportunity to
            provide us with additional information via the third-party platform
            or network, such as a list of your friends or connections and your
            email address. You can read more about your privacy choices in the
            “Third party platforms or social media networks” portion of the
            “Your Choices” section.
          </Text>
          <Text style={textSpacing}>
            <strong>Information we obtain from other third parties.</strong> We
            may receive personal information about you from third-party sources.
            For example, a business partner may share your contact information
            with us if you have expressed interest in learning specifically
            about our products or services, or the types of products or services
            we offer. We may obtain your personal information from other third
            parties, such as marketing partners, publicly-available sources and
            data providers.
          </Text>
          <Text style={textSpacing}>
            <strong>
              Cookies and Other Information Collected by Automated Means
            </strong>
          </Text>
          <Text style={textSpacing}>
            We, our service providers, and our business partners may
            automatically log information about you, your computer or mobile
            device, and activity occurring on or through the Service, including
            but not limited, your computer or mobile device operating system
            type and version number, manufacturer and model, device identifier
            (such as the Google Advertising ID or Apple ID for Advertising),
            browser type, screen resolution, IP address, the website you visited
            before browsing to our website, general location information such as
            city, state or geographic area; information about your use of and
            actions on the Service, such as pages or screens you viewed, how
            long you spent on a page or screen, navigation paths between pages
            or screens, information about your activity on a page or screen,
            access times, and length of access; and other personal information.
            Our service providers and business partners may collect this type of
            information over time and across third-party websites and mobile
            applications.{" "}
          </Text>
          <Text style={textSpacing}>
            On our webpages, this information is collected using cookies,
            browser web storage (also known as locally stored objects, or
            “LSOs”), Flash-based LSOs (also known as “Flash cookies”), web
            beacons, and similar technologies, and our emails may also contain
            web beacons. In our mobile application, we may collect this
            information directly or through our use of third-party software
            development kits (“SDKs”). SDKs may enable third parties to collect
            information directly from our App.
          </Text>
          <Text style={textSpacing}>
            A “cookie” is a text file that websites send to a visitor‘s computer
            or other Internet-connected device to uniquely identify the
            visitor’s browser or to store information or settings in the
            browser. Browser web storage, or LSOs, are used for similar purposes
            as cookies. Browser web storage enables the storage of a larger
            amount of data than cookies. A “web beacon,” also known as a pixel
            tag or clear GIF, is typically used to demonstrate that a webpage
            was accessed or that certain content was viewed, typically to
            measure the success of our marketing campaigns or engagement with
            our emails and to compile statistics about usage of our websites. A
            SDK is third-party computer code that we may incorporate into our
            mobile applications that may be used for a variety of purposes,
            including to provide us with analytics regarding the use of our
            mobile applications, to integrate with social media, add features or
            functionality to our app, or to facilitate online advertising.
          </Text>
          <Text style={textSpacing}>
            We may also use Flash LSOs on our website to collect and store
            information about your use of our website. Unlike cookies, Flash
            LSOs cannot be removed or rejected via your browser settings. If you
            do not want Flash LSOs stored on your computer or mobile device, you
            can adjust the settings of your Flash player to block Flash LSO
            storage using the tools contained in the Website Storage Settings
            Panel. You can also control Flash LSOs by going to the Global
            Storage Settings Panel and following the instructions. Please note
            that setting the Flash Player to restrict or limit acceptance of
            Flash LSOs may reduce or impede the functionality of Flash
            applications that we may use in connection with our website.
          </Text>
          <Text style={textSpacing}>
            Web browsers may offer users of our websites or mobile apps the
            ability to disable receiving certain types of cookies; however, if
            cookies are disabled, some features or functionality of our websites
            may not function correctly. Please see the “Targeted online
            advertising” section for information about how to exercise choice
            regarding the use of browsing behavior for purposes of targeted
            advertising.
          </Text>
          <Text style={textSpacing}>
            <strong>Referrals</strong>
          </Text>
          <Text style={textSpacing}>
            Users of the Service may have the opportunity to refer friends or
            other contacts to us. If you are an existing user, you may only
            submit a referral if you have permission to provide the referral’s
            contact information to us so that we may contact them.{" "}
          </Text>
          <Text style={textSpacing}></Text>
        </section>

        <section
          style={{ marginBottom: "12px" }}
          id={"personal-information-usage"}
        >
          <Heading fontSize={"2xl"}>
            How We Use Your Personal Information
          </Heading>
          <Text style={textSpacing}>
            We use your personal information for the following purposes and as
            otherwise described in this Privacy Policy or at the time of
            collection:
          </Text>
          <Text style={textSpacing}>
            <strong>To operate the Service.</strong> We use your personal
            information to:
          </Text>
          <UnorderedList>
            {personalInformtionUsage.map((item, i) => {
              return <ListItem key={i}>{item}</ListItem>;
            })}
          </UnorderedList>
          <Text style={textSpacing}>
            <strong>For research and development.</strong> We analyze use of the
            Service to analyze and improve the Service and to develop new
            products and services, including by studying user demographics and
            use of the Service.
          </Text>
          <Text style={textSpacing}>
            <strong>
              To send you marketing and promotional communications.
            </strong>{" "}
            We may send you Tempo-related marketing communications as permitted
            by law. You will have the ability to opt-out of our marketing and
            promotional communications as described in the Opt out of marketing
            section below.
          </Text>
          <Text style={textSpacing}>
            <strong>To display advertisements.</strong> We work with advertising
            partners to display advertisements on the Service or elsewhere
            online. These advertisements are delivered by our advertising
            partners and may be targeted based on your use of the Service or
            your activity elsewhere online. To learn more about your choices in
            connection with advertisements, please see the section below titled
            “Targeted online advertising.”
          </Text>
          <Text style={textSpacing}>
            <strong>To comply with law.</strong> We use your personal
            information as we believe necessary or appropriate to comply with
            applicable laws, lawful requests, and legal process, such as to
            respond to subpoenas or requests from government authorities.
          </Text>
          <Text style={textSpacing}>
            <strong>For compliance, fraud prevention, and safety.</strong> We
            may use your personal information and disclose it to law
            enforcement, government authorities, and private parties as we
            believe necessary or appropriate to: (a) protect our, your or
            others’ rights, privacy, safety or property (including by making and
            defending legal claims); (b) enforce the terms and conditions that
            govern the Service; and (c) protect, investigate and deter against
            fraudulent, harmful, unauthorized, unethical or illegal activity.
          </Text>
          <Text style={textSpacing}>
            <strong>With your consent.</strong> In some cases we may
            specifically ask for your consent to collect, use or share your
            personal information, such as when required by law.
          </Text>
          <Text style={textSpacing}>
            <strong>
              To create anonymous, aggregated or de-identified data.
            </strong>{" "}
            We may create anonymous, aggregated or de-identified data from your
            personal information and other individuals whose personal
            information we collect. We make personal information into anonymous,
            aggregated or de-identified data by removing information that makes
            the data personally identifiable to you. We may use this anonymous,
            aggregated or de-identified data and share it with third parties for
            our lawful business purposes, including to analyze and improve the
            Service and promote our business.
          </Text>
        </section>
        <section
          style={{ marginBottom: "12px" }}
          id={"personal-information-share"}
        >
          <Heading fontSize={"2xl"}>
            How We Share your Personal Information
          </Heading>
          <Text style={textSpacing}>
            We do not share your personal information with third parties without
            your consent, except in the following circumstances or as described
            in this Privacy Policy:
          </Text>
          <Text style={textSpacing}>
            <strong>Affiliates.</strong> We may share your personal information
            with our corporate parent, subsidiaries, and affiliates, for
            purposes consistent with this Privacy Policy.
          </Text>
          <Text style={textSpacing}>
            <strong>Service providers.</strong> We may share your personal
            information with third party companies and individuals that provide
            services on our behalf or help us operate the Service (such as
            customer support, hosting, analytics, email delivery, marketing, and
            database management services). These third parties may use your
            personal information only as directed or authorized by us and in a
            manner consistent with this Privacy Policy, and are prohibited from
            using or disclosing your information for any other purpose.
          </Text>
          <Text style={textSpacing}>
            <strong>Partners.</strong> We may sometimes share your personal
            information with partners or enable partners to collect information
            directly via our Service.
          </Text>
          <Text style={textSpacing}>
            <strong>Third-party platforms and social media networks.</strong> If
            you have enabled features or functionality that connect the Service
            to a third-party platform or social media network (such as by
            logging in to the Service using your account with the third-party,
            providing your API key or similar access token for the Service to a
            third-party, or otherwise linking your account with the Service to a
            third-party’s services), we may disclose the personal information
            that you authorized us to share. We do not control the third party’s
            use of your personal information.
          </Text>
          <Text style={textSpacing}>
            <strong>Other Users of the Service and the Public.</strong> We may
            provide functionality that enables you to disclose personal
            information to other users of the Service or the public. For
            instance, you may be able to maintain a user profile with
            information about yourself that you can make available to other
            users or the public. You may also be able to submit content to the
            Service (such as comments, reviews, surveys, blogs, photos, and
            videos), and we will display your name, username, and a link to your
            user profile along with the content you submit. We may make
            available settings through the Profile page that enable you to
            exercise choice regarding certain information that is displayed
            publicly or to other users. We do not control how other users or
            third parties use any personal information that you make available
            to such users or the public.
          </Text>
          <Text style={textSpacing}>
            <strong>Professional advisors.</strong> We may disclose your
            personal information to professional advisors, such as lawyers,
            bankers, auditors and insurers, where necessary in the course of the
            professional services that they render to us.
          </Text>
          <Text style={textSpacing}>
            <strong>For compliance, fraud prevention and safety.</strong> We may
            share your personal information for the compliance, fraud prevention
            and safety purposes described above.{" "}
          </Text>
          <Text style={textSpacing}>
            <strong>Business transfers.</strong> We may sell, transfer or
            otherwise share some or all of our business or assets, including
            your personal information, in connection with a business transaction
            (or potential business transaction) such as a corporate divestiture,
            merger, consolidation, acquisition, reorganization or sale of
            assets, or in the event of bankruptcy or dissolution.{" "}
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"your-choice"}>
          <Heading fontSize={"2xl"}>Your Choices</Heading>
          <Text style={textSpacing}>
            In this section, we describe the rights and choices available to all
            users.
          </Text>
          <Text style={textSpacing}>
            <strong>Access or Update Your Information.</strong> If you have
            registered for an account with us, you may review and update certain
            personal information in your account profile by logging into the
            account.
          </Text>
          <Text style={textSpacing}>
            <strong>Opt out of marketing communications.</strong> You may opt
            out of marketing-related emails by following the opt-out or
            unsubscribe instructions at the bottom of the email. You may
            continue to receive service-related and other non-marketing emails.
            If you receive marketing text messages from us, you may opt out of
            receiving further marketing text messages from us by replying STOP
            to our marketing message.
          </Text>
          <Text style={textSpacing}>
            <strong>Cookies & Browser Web Storage.</strong> We may allow service
            providers and other third parties to use cookies and similar
            technologies to track your browsing activity over time and across
            the Service and third party websites. Most browsers let you remove
            or reject cookies. To do this, follow the instructions in your
            browser settings. Many browsers accept cookies by default until you
            change your settings. Please note that if you set your browser to
            disable cookies, the Sites may not work properly. Similarly, your
            browser settings may allow you to clear your browser web storage.{" "}
          </Text>
          <Text style={textSpacing}>
            <strong>Privacy settings and location data.</strong> Users of our
            mobile application also have the choice whether to allow us to
            access your precise location data. Your device settings may provide
            the ability for you to revoke our ability to access location data.
          </Text>
          <Text style={textSpacing}>
            <strong>Targeted online advertising.</strong> Some of the business
            partners that collect information about users’ activities on or
            through the Service may be members of organizations or programs that
            provide choices to individuals regarding the use of their browsing
            behavior or mobile application usage for purposes of targeted
            advertising.{" "}
          </Text>
          <Text style={textSpacing}>
            Users may opt out of receiving targeted advertising on websites
            through members of the Network Advertising Initiative by clicking
            here or the Digital Advertising Alliance by clicking here. Users of
            our mobile applications may opt out of receiving targeted
            advertising in mobile apps through participating members of the
            Digital Advertising Alliance by installing the AppChoices mobile
            app, available here, and selecting the user’s choices. Please note
            that we also may work with companies that offer their own opt-out
            mechanisms and may not participate in the opt-out mechanisms that we
            linked above.
          </Text>
          <Text style={textSpacing}>
            In addition, your mobile device settings may provide functionality
            to limit our, or our partners’, ability to engage in ad tracking or
            targeted advertising using the Google Advertising ID or Apple ID for
            Advertising associated with your mobile device.
          </Text>
          <Text style={textSpacing}>
            If you choose to opt-out of targeted advertisements, you will still
            see advertisements online but they may not be relevant to you. Even
            if you do choose to opt out, not all companies that serve online
            behavioral advertising are included in this list, so you may still
            receive some cookies and tailored advertisements from companies that
            are not listed.
          </Text>
          <Text style={textSpacing}>
            <strong>Do Not Track.</strong> Some Internet browsers may be
            configured to send “Do Not Track” signals to the online services
            that you visit. We currently do not respond to “Do Not Track” or
            similar signals. To find out more about “Do Not Track,” please visit
            http://www.allaboutdnt.com.
          </Text>
          <Text style={textSpacing}>
            <strong>Choosing not to share your personal information.</strong>{" "}
            Where we are required by law to collect your personal information,
            or where we need your personal information in order to provide the
            Service to you, if you do not provide this information when
            requested (or you later ask to delete it), we may not be able to
            provide you with our services. We will tell you what information you
            must provide to receive the Service by designating it as required at
            the time of collection or through other appropriate means.
          </Text>
          <Text style={textSpacing}>
            <strong>Third-party platforms or social media networks.</strong> If
            you choose to connect to the Service via a third-party platform or
            social media network, you may have the ability to limit the
            information that we may obtain from the third-party at the time you
            login to the Service using the third-party’s authentication service
            or otherwise connect your account. Subsequently, you may be able to
            control your settings through the third-party’s platform or service.
            For example, you may access and change your settings through the
            Facebook settings page for Apps and Websites. If you withdraw our
            ability to access certain information from a third-party platform or
            social media network, that choice will not apply to information that
            we have already received from that third-party.
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"other-services"}>
          <Heading fontSize={"2xl"}>
            Other sites, mobile applications and services
          </Heading>
          <Text style={textSpacing}>
            The Service may contain links to other websites, mobile
            applications, and other online services operated by third parties.
            These links are not an endorsement of, or representation that we are
            affiliated with, any third party. In addition, our content may be
            included on web pages or in mobile applications or online services
            that are not associated with us. We do not control third party
            websites, mobile applications or online services, and we are not
            responsible for their actions. Other websites, mobile applications
            and services follow different rules regarding the collection, use
            and sharing of your personal information. We encourage you to read
            the privacy policies of the other websites, mobile applications and
            online services you use.
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"security-practices"}>
          <Heading fontSize={"2xl"}>Security practices</Heading>
          <Text style={textSpacing}>
            The security of your personal information is important to us. We
            employ a number of organizational, technical and physical safeguards
            designed to protect the personal information we collect. However,
            security risk is inherent in all internet and information
            technologies and we cannot guarantee the security of your personal
            information.
          </Text>
        </section>

        <section
          style={{ marginBottom: "12px" }}
          id={"international-data-transfers"}
        >
          <Heading fontSize={"2xl"}>International data transfers</Heading>
          <Text style={textSpacing}>
            We are headquartered in the United States and have service providers
            in other countries, and your personal information may be transferred
            to the United States or other locations outside of your state,
            province, or country where privacy laws may not be as protective as
            those in your state, province, or country.
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"children"}>
          <Heading fontSize={"2xl"}>Children</Heading>
          <Text style={textSpacing}>
            As a general rule, children are not allowed to use the Service, and
            we do not collect personal information from them. We define
            "children" as anyone under 13 years old.If we learn that we have
            collected personal information of a child without the consent of the
            child’s parent or guardian, we will delete it. We encourage parents
            with concerns to contact us..
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"privacy-policy-changes"}>
          <Heading fontSize={"2xl"}>Changes to this Privacy Policy </Heading>
          <Text style={textSpacing}>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on the
            Service. We may, and if required by law will, also provide
            notification of changes in another way that we believe is reasonably
            likely to reach you, such as via e-mail (if you have an account
            where we have your contact information) or another manner through
            the Service. Any modifications to this Privacy Policy will be
            effective upon our posting the new terms and/or upon implementation
            of the new changes on the Service (or as otherwise indicated at the
            time of posting). In all cases, your continued use of the Service
            after the posting of any modified Privacy Policy indicates your
            acceptance of the terms of the modified Privacy Policy.
          </Text>
        </section>

        <section style={{ marginBottom: "12px" }} id={"contact-us"}>
          <Heading fontSize={"2xl"}>How to Contact Us</Heading>
          <Text style={textSpacing}>
            Please direct any questions or comments about this Policy or privacy
            practices to info@tempo.live. You may also write to us via postal
            mail at:
          </Text>
          <Text style={textSpacing}>Tempo Live Events LLC.</Text>
          <Text style={textSpacing}>Attn: Legal – Privacy</Text>
          <Text style={textSpacing}>
            5715 Saluda Creek Ln, Houston, TX 77085
          </Text>
        </section>

        <section
          style={{ marginBottom: "12px" }}
          id={"california-privacy-rights"}
        >
          <Heading fontSize={"2xl"}>Your California Privacy Rights</Heading>
          <Text style={textSpacing}>
            Under California Civil Code section 1798.83, California residents
            are entitled to ask us for a notice identifying the categories of
            personal customer information which we share with our affiliates
            and/or third parties for marketing purposes, and providing contact
            information for such affiliates and/or third parties. If you are a
            California resident and would like a copy of this notice, please
            submit a written request to us via email at info@tempo.live. You
            must put the statement "Your California Privacy Rights" in your
            request and include your name, street address, city, state, and ZIP
            code. We are not responsible for notices that are not labeled or
            sent properly, or do not have complete information.
          </Text>
        </section>
      </div>
    </div>
  );
}
