export default {
  WHITE: "#ffffff",
  BLACK: "#000",
  TOMATO: "#eb3e32",
  PALE_GREY: "#f8f8fa",
  LIGHT_BLUE_GREY: "#aeb6c1",
  DARKISH_GREEN: "#23883c",
  DARKISH_GREEN_STATUS: "#00590e",
  CHARCOAL_GREY: "#363f4d",
  CLOUDY_BLUE: "#b7c1cf",
  DODGER_BLUE: "#43acff",
  CORN_FLOWER: "#6c63ff",
  ORANGE: "#FF9966",
  REDDISH_ORANGE: "#FF5E62",
  REDDISH_ORANGE_2: "#FF6062",
  REDDISH_ORANGE_4: "#FF6B66",
  WHISPER: "#e7e7e7",
  GRAY: "#808080",
  TAB_BAR_ACTIVE_TINT_COLOR: "#111628",
  TAB_BAR_INACTIVE_TINT_COLOR: "#B4B4B4",
  LIGHT_GREY: "#E8E5E5",
  REDDISH_ORANGE_3: "#F26C5D",
  GRAY_SEPARTOR_COLOR: "#EBEAEC",
  EXTRA_LIGHT_GRAY: "#D4D4D4",
  TICKET_INPUT_BORDER_COLOR: "rgba(151, 151, 151, 0.07)",
  TICKET_INPUT_BORDER_LINE_COLOR: "#979797",
  LIGHT_PINK_COLOR: "#FFE4E5",
  GRAY_TEXT_COLOR: "#72757A",
  GREEN_TEXT_COLOR: "#87BE56",
  SLIDER_UNSELECTED_TRACK_COLOR: "#8F92A1",
  FILTER_OPTION_UNSELECTED_BACKGROUND_COLOR: "#FFECEC",
  GRAY_RIGHT_ICON_COLOR: "#D6D6D6",
  NOTIFICATION_ORANGE_TEXT: "#EC615B",
  QR_SCANNER_BOTTOM_BLACK: "#090909",
  QR_SCANNER_FAILED_RED: "#FE5741",
  QR_SCANNER_SUCCESS_GREEN: "#44CD4C",
  QR_SCANNER_YELLOW_TEXT: "#F2C452",
  TRANSPARENT_WHITE: "rgba(255 , 255, 255, 0.2)",
};
