//Auth
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const TOKEN = "TOKEN";

//Toast
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
export const MESSAGE_WARNING = "MESSAGE_WARNING";
export const MESSAGE_INFO = "MESSAGE_INFO";
export const MESSAGE_NULL = "MESSAGE_NULL";

//EVENT
export const ALL = "ALL";
export const POPULAR = "POPULAR";
export const UPCOMING = "UPCOMING";
export const FEATURED = "FEATURED";
export const NEARBY = "NEARBY";
export const SET_PROMO_CODE_DETAIL = "SET_PROMO_CODE_DETAIL";
