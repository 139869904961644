import { IconButton } from "@chakra-ui/button";
import {
  Center,
  Divider,
  HStack,
  Text,
  VStack,
  Stack,
} from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
} from "@chakra-ui/modal";
import { useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const TicketType = ({ data, purchasingTicketDetails, isPaid }) => {
  const [ticket, setTicket] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [totalTickets, setTotalTickets] = useState(data.seats);
  const onClose = () => {
    setIsOpen(false);
  };
  const [is425] = useMediaQuery("(max-width: 425px)");
  console.log("Tickets:---", data);

  return (
    <div style={{ marginBlock: 14 }}>
      <Modal isOpen={isOpen} size="4xl" overflow="auto" onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="4">
          <ModalHeader paddingBottom="unset">Information</ModalHeader>

          <ModalCloseButton />
          <ModalBody paddingTop="unset">
            <Text mb="3" fontFamily="gilroy-bold">
              {data.label.toUpperCase()}
            </Text>
            <Text>{data.description}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack
        direction={{ base: "column-reverse", sm: "row" }}
        justifyContent={{ base: "center", sm: "flex-start" }}
        alignItems={{ base: "center", sm: "flex-start" }}
        m="6"
      >
        <VStack
          opacity={!data.isActive ? 0.5 : 1}
          spacing={2}
          m="2"
          alignItems="center"
          justifyContent={"center"}
          flex={{ base: 1, xl: 0.2, lg: 0.4, sm: 0.6 }}
        >
          {data.displaySeats && (
            <>
              <Text fontSize="md" color="#72757A">
                Seats Available
              </Text>
              <Text
                fontSize="2xl"
                color="#FF5E62"
                textAlign={"center"}
                fontFamily="gilroy-bold"
              >
                {data.seats !== 0
                  ? new Date(data.endTime) < new Date()
                    ? "-"
                    : totalTickets.toLocaleString()
                  : "sold out"}
              </Text>
            </>
          )}
          <HStack spacing={6}>
            <IconButton
              size="sm"
              rounded="full"
              isDisabled={
                !data.isActive ||
                data.seats === 0 ||
                new Date(data.endTime) < new Date()
              }
              onClick={() => {
                if (ticket - 1 > -1) {
                  const t = ticket - 1;
                  setTicket(t);
                  const d = {
                    quantity: t,
                    price: data.price,
                    label: data.label,
                    id: data.id,
                  };
                  purchasingTicketDetails(d);
                  setTotalTickets(totalTickets + 1);
                }
              }}
              style={{ backgroundColor: "gray" }}
              aria-label="Search database"
              icon={<AiOutlineMinus color="white" />}
            />
            <Text fontSize="2xl" fontWeight="bold">
              {ticket}
            </Text>
            <IconButton
              isDisabled={
                !data.isActive ||
                data.seats === 0 ||
                new Date(data.endTime) < new Date()
              }
              size="sm"
              onClick={() => {
                let t = ticket + 1;
                if (!(t > data.seats)) {
                  setTicket(t);
                  let d = {
                    quantity: t,
                    price: data.price,
                    label: data.label,
                    id: data.id,
                  };
                  purchasingTicketDetails(d);
                  setTotalTickets(totalTickets - 1);
                }
              }}
              style={{ backgroundColor: "#FF5E62" }}
              rounded="full"
              aria-label="Search database"
              icon={<AiOutlinePlus color="white" />}
            />
          </HStack>
          {data.seats === 0 || new Date(data.endTime) < new Date() ? (
            <Text fontSize="2xl" fontWeight="bold">
              Sale Ended
            </Text>
          ) : (
            ""
          )}
          {console.log("Ticket End Time:---", new Date(data.endTime))}
        </VStack>
        <Divider
          orientation={is425 ? "horizontal" : "vertical"}
          h={{ base: 0, sm: 150 }}
          opacity={{ base: 0, sm: 1 }}
          px="6"
        />
        <VStack
          flex={1}
          alignItems={{ base: "center", sm: "flex-start" }}
          justifyContent={{ base: "center", sm: "flex-start" }}
          spacing={2}
        >
          <Text
            fontSize="2xl"
            fontFamily="gilroy-semi-bold"
            textAlign={{ base: "center", sm: "left" }}
          >
            {data.label}
          </Text>
          <Text
            fontSize="2xl"
            color="#FF5E62"
            fontFamily="gilroy-bold"
            textAlign={{ base: "center", sm: "left" }}
          >
            {!isPaid ? "Free" : "$ " + data.price}
          </Text>
          <Text
            fontSize="md"
            fontFamily="gilroy-regular"
            textAlign={{ base: "center", sm: "left" }}
          >
            {data.description.split(/\n/).map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Text>
        </VStack>
      </Stack>
    </div>
  );
};

export default TicketType;
