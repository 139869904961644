import * as ActionList from "../../actions/actionsList";
const initialState = {
  upcoming: [],
  all: [],
  featured: [],
  popular: [],
  nearby: [],
  promoCodeDetails: null,
};
const message = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionList.POPULAR:
      return { ...state, popular: payload };
    case ActionList.UPCOMING:
      return { ...state, upcoming: payload };
    case ActionList.ALL:
      return { ...state, all: payload };
    case ActionList.FEATURED:
      return { ...state, featured: payload };
    case ActionList.NEARBY:
      return { ...state, nearby: payload };
    case ActionList.SET_PROMO_CODE_DETAIL:
      return { ...state, promoCodeDetails: payload };
    default:
      return state;
  }
};
export default message;
