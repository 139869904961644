import { HStack, Stack, Text, Input, Button, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import Surface from "../../../components/common/Surface";

import { useDispatch } from "react-redux";
import { ERROR } from "../../../store/actions/message";
import * as yup from "yup";
import Processing from "../../../components/Processing";
import { CONTACT_US } from "../../../store/actions/user";
const ContactUs = () => {
  const initial = {
    name: "",
    email: "",
    message: "",
  };
  const [form, setForm] = useState(initial);
  const [model, setModel] = useState(false);
  const dispatch = useDispatch();
  let schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
  });

  const handleSubmit = () => {
    schema
      .validate(form, { abortEarly: true })
      .then((c) => {
        setModel(true);
        dispatch(
          CONTACT_US(
            c,
            () => {
              setModel(false);
              setForm(initial);
            },
            () => {
              setModel(false);
            }
          )
        );
      })
      .catch(({ errors }) => {
        dispatch(ERROR(errors[0]));
      });
  };
  return (
    <div id="ContactUs">
      <Processing visible={model} title="Sending" />
      <Surface>
        <Stack
          flexDirection={{
            base: "column", // 0-48em
            lg: "row", // 48em-80em,
          }}
          p="12"
        >
          <Stack
            width={{ base: "100%", lg: "75%" }}
            style={{ marginRight: "3%" }}
          >
            <div>
              <Text fontSize="3xl" style={{ fontFamily: "gilroy-bold" }}>
                Trying to reach us?
              </Text>
              <Text fontSize="xl" style={{ fontFamily: "gilroy-medium" }}>
                Fill the form below, and we'll get back to you as fast as we
                can.
              </Text>
            </div>
            {/* <Divider
              my="2"
              style={{ backgroundColor: "gray", height: 2 }}
              size="lg"
            />
            <Text fontSize="sm" style={{ fontFamily: "gilroy-medium" }}>
              Email us at{" "}
              <a
                style={{ color: "orangered", fontWeight: "bold" }}
                href={"mailto:info@tempoevents.com"}
              >
                info@tempoevents.com
              </a>{" "}
              for more information.
            </Text> */}
          </Stack>
          <Stack
            direction="column"
            spacing={4}
            style={{ marginBlock: 12, width: "100%" }}
          >
            <Input
              py="6"
              placeholder="Name*"
              onChange={(event) =>
                setForm({ ...form, name: event.target.value })
              }
              value={form.name}
              size="md"
              variant="filled"
              style={{
                backgroundColor: "rgba(0,0,0,0.03)",
                fontSize: 16,
                fontFamily: "gilroy-medium",
              }}
            />
            <Input
              py="6"
              onChange={(event) =>
                setForm({ ...form, email: event.target.value })
              }
              value={form.email}
              placeholder="Email Address*"
              size="md"
              variant="filled"
              style={{
                backgroundColor: "rgba(0,0,0,0.03)",
                fontSize: 16,
                fontFamily: "gilroy-medium",
              }}
            />
            <Textarea
              placeholder="Message*"
              onChange={(event) =>
                setForm({ ...form, message: event.target.value })
              }
              value={form.message}
              size="lg"
              height={150}
              style={{
                backgroundColor: "rgba(0,0,0,0.03)",
                fontSize: 16,
                fontFamily: "gilroy-medium",
              }}
              variant="filled"
            />
            <HStack justifyContent="flex-end">
              <Button
                _pressed={{ backgroundColor: "#FF6B66" }}
                style={{
                  backgroundColor: "#FF5E62",
                  color: "white",
                  fontFamily: "gilroy-bold",
                }}
                onClick={handleSubmit}
              >
                SEND
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Surface>
    </div>
  );
};

export default ContactUs;
