import { Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { useHistory } from "react-router";

const Footer = () => {
  const history = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#212121",
        padding: 4,
        paddingBlock: 14,
        marginTop: 14,
      }}
    >
      <Stack
        // className="App"
        marginInline="7%"
        spacing={6}
        direction={{ base: "column", lg: "row" }}
      >
        <Text
          flexGrow={1}
          fontSize="lg"
          style={{ fontFamily: "gilroy-medium", color: "white" }}
        >
          Tempo Events ® All Rights Reserved 2023
        </Text>
        <Text
          fontSize="lg"
          onClick={() => {
            history.push("/privacy-policy");
          }}
          style={{
            fontFamily: "gilroy-medium",
            color: "white",
            cursor: "pointer",
          }}
        >
          Privacy Policy
        </Text>
        <Text
          fontSize="lg"
          onClick={() => {
            history.push("/terms-and-conditions");
          }}
          style={{
            fontFamily: "gilroy-medium",
            color: "white",
            cursor: "pointer",
          }}
        >
          Terms And Conditions
        </Text>
      </Stack>
    </div>
  );
};

export default Footer;
