import { Box } from "@chakra-ui/layout";
import React from "react";

const Surface = (props) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" boxShadow="md" {...props}>
      {props.children}
    </Box>
  );
};
export default Surface;
