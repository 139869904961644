import { Avatar, AvatarGroup } from "@chakra-ui/avatar";
import {
  Center,
  Divider,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { WARRING } from "../../store/actions/message";
import { MdLocationOn } from "react-icons/md";
import { AiFillClockCircle } from "react-icons/ai";
import "./style.css";
import Surface from "../../components/common/Surface";
import { Button } from "@chakra-ui/button";
import { useMediaQuery } from "@chakra-ui/media-query";
import Map from "./components/Map";
import Ticket from "./components/Ticket";
import { useHistory, useParams } from "react-router";
import { ADD_PROMO_VIEW, GET_EVENT_DETAILS } from "../../store/actions/events";
import Loading from "../../components/Loading";
import TicketType from "./components/TicketType";
import { FcCheckmark } from "react-icons/fc";
import { BsClipboard } from "react-icons/bs";
import { FiShare } from "react-icons/fi";

const EventDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [totalTickets, setTotalTickets] = useState([]);
  const [is425] = useMediaQuery("(max-width: 425px)");
  const [isOpen, setIsOpen] = useState(false);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const shareURL = `https://tempo.live/${id}`;
  let shareText = `Come join the ${result?.title}. Purchase a ticket at ${result?.tickets[0]?.price} from Tempo Events.`;

  const queryParameters = new URLSearchParams(window.location.search)
  const promo = queryParameters.get("promo") || ""

  useEffect(() => {
    if (promo) {
      ADD_PROMO_VIEW(id, promo)
    }
  }, [promo])

  const onSocialClose = () => {
    setIsSocialOpen(false);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const showCheckOut = () => {
    let a = totalTickets.filter((item) => item.quantity !== 0);
    console.log("why disabled", a, totalTickets);
    if (a.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(
      GET_EVENT_DETAILS(
        id,
        (v) => {
          console.log(v);
          setResult(v);
          setLoading(true);
        },
        () => {
          history.push("/notFound");
        }
      )
    );
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    } else {
      dispatch(WARRING("Location is Not Available!"));
    }
  }, []);

  if (!loading) {
    return <Loading title="Loading Event" />;
  } else
    return (
      <div className="details">
        <Modal isOpen={isOpen} size="4xl" overflow="auto" onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            style={{ height: "100%", width: "100%" }}
            backgroundColor="transparent"
            boxShadow="unset"
          >
            <ModalCloseButton
              style={{ top: -25, color: "white" }}
              rounded="full"
            />
            <ModalBody>
              <img
                src={result.artwork}
                alt="Event"
                style={{ height: "auto", width: "100%", borderRadius: 12 }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="img-div"
        >
          <img src={result.artwork} alt="Event" className="cover" />
        </div>

        <div style={{ marginTop: 20, marginInline: "7%" }}>
          <HStack spacing={5}>
            <Text
              fontSize="2xl"
              color="#FF5E62"
              style={{ fontFamily: "gilroy-medium", flexGrow: 1 }}
            >
              {result.category}
            </Text>
            {result.guestInvite &&
              (!is425 ? (
                <Button
                  alignItems="center"
                  className="social-btn"
                  leftIcon={<FiShare />}
                  variant="ghost"
                  onClick={() => {
                    setIsSocialOpen(true);
                  }}
                >
                  Share Event
                </Button>
              ) : (
                <IconButton
                  variant="ghost"
                  className="social-btn"
                  aria-label="Search database"
                  icon={<FiShare size={22} />}
                  onClick={() => {
                    setIsSocialOpen(true);
                  }}
                />
              ))}
          </HStack>

          <Text
            fontSize={{ base: "lg", sm: "4xl" }}
            style={{ fontFamily: "gilroy-semi-bold", lineHeight: "50px" }}
          >
            {result.title}
          </Text>
          <Stack spacing={3} direction={{ base: "column", sm: "row" }}>
            <div style={{ flexGrow: 1 }}>
              <HStack spacing={5} alignItems="center" my={3}>
                <AiFillClockCircle className="inactive" />
                <div>
                  <Text fontSize="md" fontWeight="medium">
                    {moment(result.startTime).format("ddd, DD MMM YYYY")}
                  </Text>
                  <Text fontSize="xs" color="#72757A">
                    {moment(result.startTime).format("LT")} to{" "}
                    {moment(result.endTime).format("LT")}
                  </Text>
                </div>
              </HStack>
              <HStack spacing={5} alignItems="center" my={4}>
                <MdLocationOn className="inactive" />
                <Text fontSize="md" fontWeight="medium">
                  {result.location}
                </Text>
              </HStack>
            </div>

            {/* <div>
              <AvatarGroup size={is425 ? "md" : "lg"} max={3} my="4">
                {result.going &&
                  result.going.map((item, i) => (
                    <Avatar name={item.name} src={item.avatar} key={i} />
                  ))}
                 <Avatar
                  name="Ryan Florence"
                  src="https://bit.ly/ryan-florence"
                />
                <Avatar
                  name="Segun Adebayo"
                  src="https://bit.ly/sage-adebayo"
                />
                <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                <Avatar
                  name="Prosper Otemuyiwa"
                  src="https://bit.ly/prosper-baba"
                />
                <Avatar
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
                <Avatar
                  name="Ryan Florence"
                  src="https://bit.ly/ryan-florence"
                />
                <Avatar
                  name="Segun Adebayo"
                  src="https://bit.ly/sage-adebayo"
                />
                <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                <Avatar
                  name="Prosper Otemuyiwa"
                  src="https://bit.ly/prosper-baba"
                />
                <Avatar
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                /> 
              </AvatarGroup>
              <Text color="#FF5E62" fontFamily="gilroy-bold">
                {result.going && result.going.length} Person going{" "}
                {result.going && result.going.length === 0 && 0}
              </Text>
            </div> */}
          </Stack>

          <Surface p="0" my="6">
            <Text
              ml="4"
              mt="4"
              fontSize={{ base: "4xl", sm: "2xl" }}
              textAlign={{ base: "center", sm: "left" }}
              fontFamily="gilroy-medium"
            >
              Ticket Selection
            </Text>
            <SimpleGrid columns={1}>
              {result.tickets.map((tickets, index) => (
                <React.Fragment key={index}>
                  <TicketType
                    key={index}
                    data={tickets}
                    isPaid={result.isPaidEvent}
                    purchasingTicketDetails={(ticketDetails) => {
                      if (totalTickets.length === 0) {
                        setTotalTickets([...totalTickets, ticketDetails]);
                      } else {
                        let a = totalTickets.filter(
                          (item, i) => item.label === ticketDetails.label
                        );
                        if (a.length > 0) {
                          let a = [...totalTickets];
                          a = a.map((tick, i) => {
                            if (ticketDetails.label === tick.label) {
                              return ticketDetails;
                            } else {
                              return tick;
                            }
                          });
                          setTotalTickets(a);
                        } else {
                          setTotalTickets([...totalTickets, ticketDetails]);
                        }
                      }
                    }}
                  />
                  <Divider opacity={{ base: 1, sm: 0 }} />
                </React.Fragment>
              ))}
            </SimpleGrid>
          </Surface>

          <Ticket data={totalTickets} />

          <Modal
            isOpen={isSocialOpen}
            size="4xl"
            overflow="auto"
            onClose={onSocialClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Share Event</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setIsCopied(false);
                }}
              />
              <ModalBody p="4">
                <SimpleGrid columns={{ base: 3, sm: 6 }} spacing={5}>
                  <FacebookShareButton
                    url={shareURL}
                    quote={shareText}
                    // hashtag={`#${id}`}
                  >
                    <FacebookIcon size={64} round />
                  </FacebookShareButton>
                  <FacebookMessengerShareButton
                    url={shareURL}
                    appId="521270401588372"
                  >
                    <FacebookMessengerIcon size={64} round />
                  </FacebookMessengerShareButton>
                  <TwitterShareButton url={shareURL} title={shareText}>
                    <TwitterIcon size={64} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareURL}
                    title={shareText}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={64} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={shareURL}>
                    <LinkedinIcon size={64} round />
                  </LinkedinShareButton>
                  <EmailShareButton
                    url={shareURL}
                    subject={shareText}
                    body="body"
                    className="Demo__some-network__share-button"
                  >
                    <EmailIcon size={64} round />
                  </EmailShareButton>
                </SimpleGrid>
                <Text my="2" fontSize="xl">
                  Share Link
                </Text>
                <InputGroup>
                  <Input
                    variant="filled"
                    placeholder="Filled"
                    value={shareURL}
                    isReadOnly
                    // disabled={true}
                  />
                  <InputRightElement
                    onClick={() => {
                      setIsCopied(true);
                      navigator.clipboard.writeText(shareURL);
                    }}
                    children={
                      isCopied ? <FcCheckmark /> : <BsClipboard color="gray" />
                    }
                  />
                </InputGroup>
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* <Stack spacing={3} direction={{ base: "column", sm: "row" }}>
            {result.guestInvite && (
              <Button
                width="full"
                className="inactive"
                variant="solid"
                onClick={() => {
                  setIsSocialOpen(true);
                }}
              >
                Share Event
              </Button>
            )}
            
          </Stack> */}
          <Button
            width="full"
            p="6"
            disabled={showCheckOut()}
            className="active"
            variant="solid"
            onClick={() => {
              history.push("/event/checkout", {
                totalTickets,
                stripeConnectId: result.user.stripeConnectId,
                id,
                name: result.title,
                isPaid: result.isPaidEvent,
                artwork: result.artwork,
                category: result.category,
                description: result.description,
                startTime: result.startTime,
                endTime: result.endTime,
                location: result.location,
                collectSalesTax: result.collectSalesTax || false ,
                salesTaxPercentage: result.salesTaxPercentage || 5,
                promo
              });
            }}
          >
            Purchase Ticket
          </Button>
          <Text fontSize={{ base: "lg", sm: "3xl" }} my="4" fontWeight="medium">
            Event Management
          </Text>
          <HStack spacing={10} overflow="auto">
            <HStack spacing={5} alignItems="center" my="2">
              <Avatar
                size="xl"
                name={result.user.name}
                src={
                  result.user.avatar
                    ? result.user.avatar
                    : "/images/placeholder.png"
                }
              />
              <div style={{ width: 180 }}>
                <Text fontSize="xl" fontWeight="bold">
                  {result.user.name}
                </Text>
                <Text fontSize="sm" fontWeight="medium" color="#72757A">
                  {result.user.post}
                </Text>
                <Text
                  fontSize="sm"
                  width={{ base: "70%", xs: "100%" }}
                  fontWeight="medium"
                  color="#72757A"
                >
                  {result.user.email}
                </Text>
              </div>
            </HStack>

            {result.cohosts.map((item, i) => (
              <HStack key={i} spacing={5} alignItems="center" my="2">
                <Avatar
                  size="xl"
                  name={item.name}
                  src={"/images/placeholder.png"}
                />
                <div style={{ width: 180 }}>
                  <Text fontSize="xl" fontWeight="bold">
                    {item.name}
                  </Text>
                  <Text fontSize="sm" fontWeight="medium" color="#72757A">
                    {item.role}
                  </Text>
                  <Text
                    fontSize="sm"
                    width={{ base: "70%", xs: "100%" }}
                    fontWeight="medium"
                    color="#72757A"
                  >
                    {item.email}
                  </Text>
                </div>
              </HStack>
            ))}
          </HStack>
          <Divider my="6" />
          <Text fontSize={{ base: "lg", sm: "3xl" }} my="4" fontWeight="medium">
            About Event
          </Text>
          <Text
            fontSize={{ base: "sm", sm: "xl" }}
            fontWeight="medium"
            color="#72757A"
          >
            {result.description.split(/\n/).map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Text>

          <HStack spacing={2} alignItems="center" my="4">
            <Text
              fontSize={{ base: "lg", sm: "3xl" }}
              my="4"
              flexGrow={1}
              fontWeight="medium"
            >
              Event Venue
            </Text>
            <Button
              className="social-btn"
              variant="ghost"
              fontSize="sm"
              style={{ alignSelf: "flex-end" }}
              textAlign="right"
              fontWeight="medium"
              onClick={() => {
                window.open(
                  "https://maps.google.com/?q=" +
                    result.latitude +
                    "," +
                    result.longitude,
                  "_blank"
                );
              }}
            >
              See in google map
            </Button>
          </HStack>

          <Map
            eventLocation={{
              lat: result.latitude,
              lng: result.longitude,
            }}
          />
          <Text fontSize={{ base: "lg", sm: "3xl" }} my="4" fontWeight="medium">
            Refund Policy
          </Text>
          <Text
            fontSize={{ base: "sm", sm: "xl" }}
            fontWeight="medium"
            color="#72757A"
            mb="8"
          >
            {result.refundPolicy === ""
              ? "No Policy Added"
              : result.refundPolicy}
          </Text>
        </div>
      </div>
    );
};
export default EventDetails;
