import { HStack, Text } from "@chakra-ui/layout";
import React from "react";
import Surface from "../../common/Surface";
import moment from "moment";
import { MdLocationOn } from "react-icons/md";
import { Button } from "@chakra-ui/button";
import { useHistory } from "react-router";
const XsCard = ({ data, handleDetails }) => {
  const history = useHistory();
  return (
    <div style={{ cursor: "pointer" }}>
      <Surface p="4" m="2" width="95%">
        <HStack spacing={4} alignItems="flex-start">
          <img
            src={data.artwork}
            style={{
              width: 85,
              height: 125,
              borderRadius: 7,
              objectFit: "cover",
            }}
            alt="Event"
          />
          <div>
            <Text
              color="#FF5E62"
              fontSize="xs"
              style={{ fontFamily: "gilroy-medium" }}
              onClick={handleDetails}
            >
              {moment(data.startTime).format("DD MMM YYYY")}
            </Text>
            <Text
              onClick={handleDetails}
              fontSize="sm"
              mt="2"
              style={{ fontFamily: "gilroy-medium" }}
            >
              {data.title}
            </Text>
            <HStack
              onClick={handleDetails}
              spacing={1}
              alignItems="center"
              my={3}
            >
              <MdLocationOn />
              <Text
                fontSize="xs"
                color="#72757A"
                style={{
                  fontFamily: "gilroy-medium",
                  whiteSpace: "nowrap",
                  width: 170,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.location}
              </Text>
            </HStack>
            <HStack alignItems="center" justifyContent="flex-end">
              <Button
                className="inactive"
                onClick={() => {
                  history.push("/" + data.eventId);
                }}
              >
                See Details
              </Button>
            </HStack>
          </div>
        </HStack>
      </Surface>
    </div>
  );
};

export default XsCard;
