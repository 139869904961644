import { Heading, SimpleGrid } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import MdCard from "../../components/card/Event/MdCard";
import SkeletonMdCard from "../../components/skeleton/Event/MdCard";
import {
  GET_ALL_NEARBY_EVENTS,
  GET_ALL_SPECIFIC_EVENTS,
} from "../../store/actions/events";
import { useMediaQuery } from "@chakra-ui/react";
import Nearby from "./components/Nearby";
import { WARRING } from "../../store/actions/message";
const Events = () => {
  const [isLargerThan1440] = useMediaQuery("(max-width: 1440px)");
  const params = useParams();
  const history = useHistory();
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.events === "nearby") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          dispatch(
            GET_ALL_NEARBY_EVENTS(
              {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              (v) => {
                console.log(v);
              }
            )
          );
          console.log("Latitude is", position.coords.latitude);
          console.log("Longitude is", position.coords.longitude);
        });
      } else {
        dispatch(WARRING("Location is Not Available!"));
      }
    } else {
      dispatch(
        GET_ALL_SPECIFIC_EVENTS(params.events, (v) => {
          setResult(v);
        })
      );
    }
  }, []);
  return (
    <div style={{ marginInline: "7%", flex: 1 }}>
      <Heading my="4">
        {params.events[0].toUpperCase() + params.events.substr(1)}
      </Heading>
      <SimpleGrid columns={{ base: 1, xl: 5, lg: 3, md: 2 }} spacing={3}>
        {result.length === 0 ? (
          [1, 2, 3, 4, 5].map((evt, i) => (
            <SkeletonMdCard key={i} width="100%" />
          ))
        ) : params.event === "nearby" ? (
          <Nearby nearby={result} />
        ) : (
          result.map((event, i) => (
            <MdCard
              width="100%"
              key={i}
              data={event}
              handleDetails={() => {
                history.push("/" + event.eventId);
              }}
            />
          ))
        )}
      </SimpleGrid>
    </div>
  );
};

export default Events;
