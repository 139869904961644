import API from "../../../api";
import * as ActionList from "../actionsList";
import { ERROR, SUCCESS } from "../message";

export const SET_ALL = (payload) => ({
  type: ActionList.ALL,
  payload,
});
export const SET_POPULAR = (payload) => ({
  type: ActionList.POPULAR,
  payload,
});
export const SET_FEATURED = (payload) => ({
  type: ActionList.FEATURED,
  payload,
});
export const SET_UPCOMING = (payload) => ({
  type: ActionList.UPCOMING,
  payload,
});

export const SET_NEARBY = (payload) => ({
  type: ActionList.NEARBY,
  payload,
});

export const SET_PROMO_CODE_DETAIL = (payload) => ({
  type: ActionList.SET_PROMO_CODE_DETAIL,
  payload,
});

export const GET_ALL_EVENTS = (limit, callback) => {
  return async (dispatch) => {
    await API.get("/event/all?limit=" + limit)
      .then((response) => {
        dispatch(SET_ALL(response.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const GET_ALL_POPULAR_EVENTS = (limit, callback) => {
  return async (dispatch) => {
    await API.get("/event/popular?limit=" + limit)
      .then((response) => {
        dispatch(SET_POPULAR(response.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const GET_ALL_UPCOMING_EVENTS = (limit, callback) => {
  return async (dispatch) => {
    await API.get("/event/upcoming?limit=" + limit)
      .then((response) => {
        dispatch(SET_UPCOMING(response.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const GET_ALL_NEARBY_EVENTS = (data, callback) => {
  return async (dispatch) => {
    await API.post("/event/nearby", data)
      .then((response) => {
        dispatch(SET_NEARBY(response.data.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const GET_ALL_FEATURED_EVENTS = (limit, callback) => {
  return async (dispatch) => {
    await API.get("/event/featured?limit=" + limit)
      .then((response) => {
        dispatch(SET_FEATURED(response.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const GET_EVENT_DETAILS = (id, callback, errorCb) => {
  return async (dispatch) => {
    await API.get("/event/" + id)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
        if (error.response.status === 404) {
          errorCb();
        }
      });
  };
};

export const STRIPE_PAYMENT = (data, callback, errCb) => {
  return async (dispatch) => {
    await API.post("/create-checkout-session", data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
        errCb();
      });
  };
};

export const GET_ALL_SPECIFIC_EVENTS = (event, callback) => {
  return async (dispatch) => {
    await API.get(`/event/${event}?limit=0`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message;
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
      });
  };
};

export const FREE_TICKET_PAYMENT = (event, callback, errorCb) => {
  return async (dispatch) => {
    await API.post(`/purchase-free-ticket`, event)
      .then((response) => {
        console.log(response.data, "ticket response");
        callback(response.data);
        dispatch(SUCCESS(response.data.message));
      })
      .catch((error) => {
        console.log(error.response);
        let content;
        if (error.response) {
          content = error.response.data.message || "Request Error";
        } else if (error.request) {
          content = "Something went wrong. Try again";
        } else {
          content = error.message;
        }
        dispatch(ERROR(content));
        errorCb();
      });
  };
};

export const APPLY_PROMO_CODE = ({ promoCode, tickets, eventId }) => {
  return async (dispatch) => {
    try {
      const result = await API.post(
        `/user/event/${eventId}/promocode/${promoCode}/apply`,
        { web: true, tickets }
      );
      dispatch(SET_PROMO_CODE_DETAIL(result.data.data));
    } catch (err) {
      dispatch(ERROR(err.response ? err.response.data.message : err.message));
    }
  };
};

export const ADD_PROMO_VIEW = async (eventId, promoLink) => {
  try {
    await API.post(
      `/event/add-promo-view`,
      { eventId, promoLink }
    );
    return true;
  } catch (err) {
    return err;
  }
}
