import React from "react";
import Surface from "../../common/Surface";
import { SkeletonCircle, SkeletonText } from "@chakra-ui/skeleton";
import { useMediaQuery } from "@chakra-ui/media-query";
const MdCard = ({ handleDetails, width = 300 }) => {
  const [is1024] = useMediaQuery("(min-width: 1024px)");
  return (
    <div
      style={{
        marginTop: 12,
        display: "inline-block",
        paddingRight: 12,
        paddingBottom: 6,
        cursor: "pointer",
        maxWidth: 500,
        width,
      }}
      onClick={handleDetails}
    >
      <Surface>
        <SkeletonCircle
          size="16"
          style={{
            height: 180,
            width: "100%",
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />

        <div style={{ margin: 20 }}>
          <SkeletonText noOfLines={7} spacing="4" />
        </div>
      </Surface>
    </div>
  );
};

export default MdCard;
