import { Button } from "@chakra-ui/button";
import { HStack, SimpleGrid, Text } from "@chakra-ui/layout";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import SmCard from "../../../components/card/Event/SmCard";
import SkeletonSmCard from "../../../components/skeleton/Event/SmCard";

const AllEvents = () => {
  const all = useSelector((state) => state.Event.all);
  const history = useHistory();
  return (
    <div>
      <div id="allEvents" style={{ marginBottom: 25 }}></div>
      <HStack spacing={1} alignItems="center" my={3}>
        <Text
          mb="4"
          fontSize="3xl"
          style={{ fontFamily: "gilroy-bold" }}
          flexGrow={1}
        >
          ALL EVENTS
        </Text>
        {all.length > 9 && (
          <Button
            className="social-btn"
            variant="ghost"
            onClick={() => {
              history.push("/events/all");
            }}
          >
            Explore more
          </Button>
        )}
      </HStack>

      <SimpleGrid columns={{ base: 1, xl: 3, md: 2 }} spacing={3}>
        {all.length === 0
          ? (
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: 12,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: 300,
                marginBlock: 12,
              }}
            >
              <img
                src="/images/emoji.png"
                alt="No Event"
                height={100}
                width={100}
              />
              <Text my="2" fontFamily="gilroy-bold" fontSize="xl" color="#fb4e58">
                No Events
              </Text>
            </div>
          )
          // [1, 2, 3, 4, 5, 6, 7, 7, 8, 9, 6, 4].map((evt, i) => (
          //     <SkeletonSmCard key={i} />
          //   ))
          : all.map((events, i) => (
            <SmCard
              key={i}
              data={events}
              handleDetails={() => {
                history.push("/" + events.eventUrl);
              }}
            />
          ))}
      </SimpleGrid>
    </div>
  );
};

export default AllEvents;
