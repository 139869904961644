import { HStack, Text } from "@chakra-ui/layout";
import React from "react";
import Surface from "../../common/Surface";
import moment from "moment";
import { MdLocationOn } from "react-icons/md";
import { AiFillClockCircle } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";
import { useMediaQuery } from "@chakra-ui/media-query";
const MdCard = ({ data, handleDetails, width = 300, event = "" }) => {
  const [is1024] = useMediaQuery("(min-width: 1024px)");
  return (
    <div
      style={{
        marginTop: 12,
        display: "inline-block",
        paddingRight: 12,
        paddingBottom: 6,
        width: "100%",
        maxWidth: 400,
        cursor: "pointer",
      }}
      onClick={handleDetails}
    >
      <Surface>
        <div style={{ position: "relative" }}>
          <img
            src={data.artwork}
            alt="event"
            style={{
              height: 180,
              width: "100%",
              borderTopRightRadius: 7,
              borderTopLeftRadius: 7,
              objectFit: "cover",
            }}
          />
          {data.featured && <Text className="featured">FEATURED</Text>}
        </div>
        <div style={{ margin: 12 }}>
          <Text
            color="#FF5E62"
            fontSize="xs"
            style={{ fontFamily: "gilroy-semi-bold" }}
          >
            {moment(data.startTime).format("DD MMM YYYY")}
          </Text>
          <Text
            fontSize="lg"
            width="90%"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "gilroy-semi-bold",
            }}
          >
            {data.title}
          </Text>
          <HStack spacing={1} alignItems="center" my={3}>
            <MdLocationOn />
            <Text
              fontSize="xs"
              color="#72757A"
              width="80%"
              style={{
                fontFamily: "gilroy-medium",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data.location}
            </Text>
          </HStack>
          <HStack spacing={1} alignItems="center" my={3}>
            <AiFillClockCircle />
            <Text
              fontSize="xs"
              color="#72757A"
              style={{ fontFamily: "gilroy-medium" }}
            >
              {moment(data.startTime).format("LT")} -{" "}
              {moment(data.endTime).format("LT")}
            </Text>
          </HStack>
        </div>
        {/* <HStack
          width="100%"
          className="active"
          style={{ borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
        >
          <Text flexGrow={0.8} py="5" px="3.5" fontFamily="gilroy-bold">
            {event === "popular"
              ? data.viewCount.toLocaleString() + " viewed this event"
              : data.ticketPrices.length === 0
              ? "Free"
              : `Buy a Ticket for $${data.ticketPrices[0]}`}
          </Text>
          <BsChevronRight color="white" />
        </HStack> */}
        {/* {data.going && data.going.length === 0 ? (
          <HStack
            width="100%"
            className="active"
            style={{ borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}>
            <Text flexGrow={0.8} py="5" px="3.5" fontFamily="gilroy-bold">
              {data.ticketPrices.length === 0
                ? "Free"
                : `Buy a Ticket for ${data.ticketPrices[0]}`}
            </Text>
            <AiFillCaretRight color="white" />
          </HStack>
        ) : (
          <Box mx="2" my="3">
            <AvatarGroup
              size="sm"
              max={3}
              py="2"
              style={{ fontFamily: "gilroy-medium" }}>
              {data.going &&
                data.going.map((item, i) => (
                  <Avatar name={item.name} key={i} src={item.avatar} />
                ))}
            </AvatarGroup>
          </Box>
        )} */}
      </Surface>
    </div>
  );
};

export default MdCard;
