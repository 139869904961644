import { Text } from "@chakra-ui/layout";
import { CircularProgress } from "@material-ui/core";
import React from "react";

const Loading = ({ size, title }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: size ? "unset" : "81vh",
        flexDirection: "column",
        margin: 20,
      }}>
      <CircularProgress size={size ? size : 90} thickness={2} />
      <Text mt="4" fontFamily="gilroy-bold" fontSize="xl">
        {title}
      </Text>
    </div>
  );
};

export default Loading;
