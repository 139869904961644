import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const PaymentExpired = () => {
  return (
    <div id="notfound">
      <div class="notfound">
        <img
          src={"/images/payment_expired.jpg"}
          alt="Fahad"
          width={"100%"}
          height={120}
        />
        <h2>Oops! The payment cannot be found</h2>
        <p>
          Sorry but the payment you are looking for does not exist or have been
          removed, or name changed or is temporarily unavailable
        </p>
        <Link to="/" className="link">
          Back to homepage
        </Link>
      </div>
    </div>
  );
};

export default PaymentExpired;
