import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../../config";

function Map({ eventLocation, height = "400px" }) {
  const containerStyle = {
    height,
    borderRadius: 12,
    marginBlock: 14,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });
  console.log(eventLocation);

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    // map.zoom = 17;
    map.panTo(eventLocation);
    map.setCenter(eventLocation);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={eventLocation}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={eventLocation} icon="/images/EventLocation.svg" />
    </GoogleMap>
  ) : null;
}

export default React.memo(Map);
