import { Divider, Stack } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  GET_ALL_EVENTS,
  GET_ALL_FEATURED_EVENTS,
  GET_ALL_NEARBY_EVENTS,
  GET_ALL_POPULAR_EVENTS,
  GET_ALL_UPCOMING_EVENTS,
  SET_ALL,
  SET_FEATURED,
  SET_POPULAR,
  SET_UPCOMING,
} from "../../store/actions/events";
import { WARRING } from "../../store/actions/message";
import AllEvents from "./components/AllEvents";
import ContactUs from "./components/ContactUs";
import FilteredEvents from "./components/FilteredEvents";
import Mobile from "./components/Mobile";
import firebase from "firebase";
import { FirebaseConfig, FirebaseStagingConfig } from "../../config";
console.log(process.env.REACT_APP_FIREBASE_ENV, "ah");

if (firebase.apps.length === 0) {
  if (process.env.REACT_APP_FIREBASE_ENV === "staging") {
    firebase.initializeApp(FirebaseStagingConfig);
  } else {
    firebase.initializeApp(FirebaseConfig);
  }
}
const db = firebase.firestore();
const Landing = () => {
  const dispatch = useDispatch();
  // Upcoming Events Snapsho

  useEffect(() => {
    const allEventsSubscriber = db
      .collection("events")
      .where("isArchived", "==", false)
      .where("live", "==", true)
      .where("private", "==", false)
      .where("endTime", ">=", +new Date())
      .orderBy("endTime", "desc")
      .limit(10)
      .onSnapshot(
        { includeMetadataChanges: true },
        (querySnapshot) => {
          let result = [];

          for (const item of querySnapshot.docs) {
            result.push(item.data());
          }
          dispatch(SET_ALL(result));
        },
        (err) => console.log("Upcoming Snapshot", err)
      );
    const upcomingSubscriber = db
      .collection("events")
      .where("isArchived", "==", false)
      .where("live", "==", true)
      .where("private", "==", false)
      .where("endTime", ">=", +new Date())
      .orderBy("endTime", "desc")
      .limit(10)
      .onSnapshot(
        { includeMetadataChanges: true },
        (querySnapshot) => {
          let result = [];

          for (const item of querySnapshot.docs) {
            result.push(item.data());
          }
          dispatch(SET_UPCOMING(result));
        },
        (err) => console.log("Upcoming Snapshot", err)
      );
    const featuredSubscriber = db
      .collection("events")
      .where("isArchived", "==", false)
      .where("featured", "==", true)
      .where("live", "==", true)
      .where("private", "==", false)
      .where("endTime", ">=", +new Date())
      .orderBy("endTime", "desc")
      .limit(10)
      .onSnapshot(
        { includeMetadataChanges: true },
        (querySnapshot) => {
          let result = [];

          for (const item of querySnapshot.docs) {
            result.push(item.data());
          }
          dispatch(SET_FEATURED(result));
        },
        (err) => console.log("Upcoming Snapshot", err)
      );
    const popularSubscriber = db
      .collection("events")
      .where("isArchived", "==", false)
      .where("live", "==", true)
      .orderBy("viewCount", "desc")
      .limit(10)
      .onSnapshot(
        { includeMetadataChanges: true },
        (querySnapshot) => {
          let result = [];

          for (const item of querySnapshot.docs) {
            result.push(item.data());
          }
          dispatch(SET_POPULAR(result));
        },
        (err) => console.log("Upcoming Snapshot", err)
      );
    return () => {
      upcomingSubscriber();
      allEventsSubscriber();
      featuredSubscriber();
      popularSubscriber();
    };
  }, []);

  useEffect(() => {
    // dispatch(GET_ALL_EVENTS(12, (v) => {}));
    // dispatch(GET_ALL_FEATURED_EVENTS(12, (v) => {}));
    // dispatch(GET_ALL_POPULAR_EVENTS(12, (v) => {}));
    // dispatch(GET_ALL_UPCOMING_EVENTS(12, (v) => {}));
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(
          GET_ALL_NEARBY_EVENTS(
            {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            (v) => {}
          )
        );
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    } else {
      dispatch(WARRING("Location is Not Available!"));
    }
  }, []);
  return (
    <div style={{ marginTop: 20, marginInline: "7%" }}>
      <Stack spacing={5}>
        <FilteredEvents />
        <Divider my="4" />
        <AllEvents />
        <Divider style={{ marginTop: 35, marginBottom: 12 }} />
        <Mobile />
        <ContactUs />
      </Stack>
    </div>
  );
};
export default Landing;
