import { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { STRIPE_KEY } from "../../config";
import { ERROR } from "../../store/actions/message";
import colors from "../../constant/colors";

const stripePromise = loadStripe(STRIPE_KEY);

const PaymentForm = ({ intentInfo, onComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: intentInfo.success_url,
      },
    });

    onComplete();
    setLoading(false);

    if (result.error) {
      dispatch(ERROR(result.error.message));
    } else {
      window.location.href = intentInfo.cancel_url;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        isFullWidth
        mt={5}
        variant="solid"
        backgroundColor={colors.REDDISH_ORANGE_3}
        color={colors.WHITE}
        disabled={!stripe}
        isLoading={loading}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

const StripeCheckout = ({ open, intentInfo, onClose }) => {
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent padding={10}>
        <ModalCloseButton />
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: intentInfo?.paymentIntent }}
        >
          <PaymentForm
            intentInfo={intentInfo}
            onComplete={onClose}
          ></PaymentForm>
        </Elements>
      </ModalContent>
    </Modal>
  );
};

export { StripeCheckout };
