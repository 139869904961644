import { Button } from "@chakra-ui/button";
import { HStack, Stack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./style.css";
const FilterBar = ({ selectedOption, is425 }) => {
  const history = useHistory();
  const [active, setActive] = useState("upcoming");
  const events = useSelector((state) => state.Event);
  // const [is425] = useMediaQuery("(min-width: 425px)");
  return (
    <div className="filterBar">
      <Stack direction={{ base: "column", md: "row" }}>
        <HStack spacing={2} flexGrow={1}>
          <Button
            className={active === "upcoming" ? "active" : "inactive"}
            variant="solid"
            onClick={() => {
              setActive("upcoming");
              selectedOption("upcoming");
            }}
          >
            Upcoming
          </Button>
          <Button
            className={active === "featured" ? "active" : "inactive"}
            variant="solid"
            onClick={() => {
              setActive("featured");
              selectedOption("featured");
            }}
          >
            Featured
          </Button>
          <Button
            className={active === "popular" ? "active" : "inactive"}
            variant="solid"
            onClick={() => {
              setActive("popular");
              selectedOption("popular");
            }}
          >
            Popular
          </Button>
          <Button
            className={active === "nearby" ? "active" : "inactive"}
            variant="solid"
            onClick={() => {
              setActive("nearby");
              selectedOption("nearby");
            }}
          >
            Nearby
          </Button>
        </HStack>
        {events[active].length > 0 && !is425 && (
          <HStack spacing={2} justifyContent="flex-end">
            <Button
              mt="2"
              className="social-btn"
              variant="ghost"
              onClick={() => {
                history.push("/events/" + active);
              }}
            >
              See more
            </Button>
          </HStack>
        )}
      </Stack>
    </div>
  );
};

export default FilterBar;
