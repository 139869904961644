import { HStack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import MdCard from "../../../components/card/Event/MdCard";
import SkeletonMdCard from "../../../components/skeleton/Event/MdCard";
import FilterBar from "./FilterBar";
import "./style.css";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Nearby from "./Nearby";
import { Button } from "@chakra-ui/button";
import { useMediaQuery } from "@chakra-ui/media-query";
const FilteredEvents = () => {
  const history = useHistory();
  const events = useSelector((state) => state.Event);
  const [result, setResult] = useState(events.upcoming);
  const [event, setEvent] = useState("upcoming");
  const [isEmpty, setIsEmpty] = useState(false);
  const [is425] = useMediaQuery("(max-width: 425px)");
  useEffect(() => {
    setResult(events.upcoming);
    if (!events.upcoming?.length) {
      setIsEmpty(true);
    }
  }, [events.upcoming]);

  console.log('OHIWEANLDKSZ', isEmpty)
  return (
    <div>
      <FilterBar
        is425={is425}
        selectedOption={(v) => {
          setResult(events[v]);
          if (events[v].length === 0) {
            setIsEmpty(true);
          } else {
            setIsEmpty(false);
          }
          setEvent(v);
        }}
      />
      {event !== "nearby" ? (
        isEmpty ? (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: 12,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: 300,
              marginBlock: 12,
            }}
          >
            <img
              src="/images/emoji.png"
              alt="No Event"
              height={100}
              width={100}
            />
            <Text my="2" fontFamily="gilroy-bold" fontSize="xl" color="#fb4e58">
              No {event[0].toUpperCase() + event.substr(1)} Events
            </Text>
          </div>
        ) : (
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={40}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1440,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              laptop: {
                breakpoint: {
                  max: 1440,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {result.length === 0
              ? [1, 2, 3, 4, 5, 6, 7, 8].map((evt, i) => (
                  <SkeletonMdCard width={is425 ? "70%" : 400} key={i} />
                ))
              : result.map((evt, i) => (
                  <MdCard
                    event={event}
                    width={is425 ? "100%" : 300}
                    key={i}
                    data={evt}
                    handleDetails={() => {
                      history.push("/" + evt.eventUrl);
                    }}
                  />
                ))}
          </Carousel>
        )
      ) : (
        <Nearby />
      )}
      {events[event].length > 0 && is425 && (
        <HStack spacing={2} justifyContent="flex-end">
          <Button
            mt="2"
            className="social-btn"
            variant="ghost"
            onClick={() => {
              history.push("/events/" + event);
            }}
          >
            See more
          </Button>
        </HStack>
      )}
    </div>
  );
};

export default FilteredEvents;
