import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputRightElement, InputLeftAddon } from "@chakra-ui/input";
import { HStack, Stack, Text, SimpleGrid } from "@chakra-ui/layout";
import { useMediaQuery, IconButton, Image } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import Processing from "../../components/Processing";
import {
  APPLY_PROMO_CODE,
  FREE_TICKET_PAYMENT,
  SET_PROMO_CODE_DETAIL,
  STRIPE_PAYMENT,
} from "../../store/actions/events";
import { ERROR } from "../../store/actions/message";
import Ticket from "../EventDetails/components/Ticket";
import "./style.css";
import { MdLocationOn } from "react-icons/md";
import { AiFillClockCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import colors from "../../constant/colors";
import { StripeCheckout } from "./StripePayment";
import { ABSTRACT_PHONE_VALIDATION_URL, ABSTRACT_EMAIL_VALIDATION_URL, SENDGRID_EMAIL_VALIDATION_URL, ABSTRACT_PHONE_API_KEY, ABSTRACT_EMAIL_API_KEY, SENDGRID_API_KEY } from "../../config";
import flag from "./flag-US.svg";

const Checkout = () => {
  const {
    state: {
      totalTickets,
      stripeConnectId,
      id,
      isPaid,
      name,
      artwork,
      category,
      startTime,
      endTime,
      location,
      collectSalesTax,
      salesTaxPercentage,
      promo
    },
  } = useLocation();
  console.log("collectSalesTax:---", collectSalesTax, "salesTaxPercentage:---", salesTaxPercentage);
  const [is425] = useMediaQuery("(max-width: 768px)");
  const history = useHistory();
  const dispatch = useDispatch();
  const [model, setModel] = useState(false);
  const [intentInfo, setIntentInfo] = useState(null);
  const initial = {
    name: "",
    phoneNumber: "",
    email: "",
  };
  const [promoCode, setPromoCode] = useState("");
  const [validatingPromoCode, setValidatingPromoCode] = useState(false);

  const promoCodeDetails = useSelector((store) => store.Event.promoCodeDetails);

  const [form, setForm] = useState(initial);
  let schema = yup.object().shape({
    name: yup.string().required(),
    phoneNumber: yup.string().required("Please enter a valid phone number").min(10, "Phone number should be 10 digits").max(10, "Phone number should be 10 digits"),
    email: yup.string().email().required(),
  });
  async function verifyUserPhone(phone) {
    return await fetch(`${ABSTRACT_PHONE_VALIDATION_URL}/?api_key=${ABSTRACT_PHONE_API_KEY}&phone=${"1" + phone}`, {
      method: 'GET',
      redirect: 'follow'
    }).then(response => response.json())
      .then((res) => {
        return res.valid;
      }).catch((err) => { return false })
  }
  async function verifyUserEmail(email) {

    const data = JSON.stringify({
      "email": email
    });
    return await fetch(SENDGRID_EMAIL_VALIDATION_URL, {
      method: 'POST',
      headers: { "Authorization": SENDGRID_API_KEY, "Content-Type": "application/json" },
      body: data,
    })
      .then(response => response.json())
      .then((data) => {
        return data.result.verdict === "Valid"
      }).catch((err) => { return false })
  }
  function calculateSalesTax(subtotal, totalQuantityOfTickets) {
    return parseFloat((subtotal * 0.07 + totalQuantityOfTickets).toFixed(2));
  }
  function calculateActualSalesTax(subtotal, salesTaxPercentage) {
    if (!collectSalesTax) {
      return 0;
    }
    return parseFloat(subtotal * (salesTaxPercentage / 100));
  }
  const handleCheckout = async () => {
    try {
      const formData = await schema.validate(form, { abortEarly: true })

      // const validatePhone = await verifyUserPhone(formData.phoneNumber)
      // if (validatePhone) {
      const validateEmail = await verifyUserEmail(formData.email);
      if (validateEmail) {

        setModel(true);
        const lineItems = [];
        let subtotal = 0;
        let q = 0;

        for (const i of totalTickets) {
          lineItems.push({
            price_data: {
              currency: "usd",
              product_data: { name: i.label },
              unit_amount: Math.round(
                (i.price +
                  i.price * 0.07 +
                  1 +
                  i.price * (salesTaxPercentage / 100)) *
                100
              ),
            },
            quantity: i.quantity,
          });

          subtotal = subtotal + i.price * i.quantity;
          q = q + i.quantity;
        }

        const promoCodeAmount = promoCodeDetails?.amount_off ?? 0;

        let data = {
          ...formData,
          lineItems,
          stripeConnectId,
          eventId: id,
          subtotal,
          promocode: isPaid ? promoCodeDetails : null,
          promocodeAmount: isPaid ? promoCodeAmount : null,
          tickets: totalTickets,
          salesTax: calculateSalesTax(
            subtotal - (promoCodeDetails?.amount_off ?? 0),
            q
          ),
          actualTax: calculateActualSalesTax(subtotal, salesTaxPercentage),
          promoLink: promo
        };
        if (!isPaid) {
          delete data.stripeConnectId;
          delete data.lineItems;
          dispatch(
            FREE_TICKET_PAYMENT(
              data,
              (res) => {
                setModel(false);
                if (res.status) {
                  // window.location.ref = res.data
                  window.location.href =
                    window.location.protocol + "//" + res.data;
                }
              },
              () => {
                setModel(false);
              }
            )
          );
        } else {
          dispatch(
            STRIPE_PAYMENT(
              data,
              (res) => {
                setModel(false);
                setIntentInfo(res);
                console.log(res);
              },
              () => {
                setModel(false);
              }
            )
          );
        }
      } else dispatch(ERROR("Could not verify email"));

      // } else dispatch(ERROR("Could not verify phone number"));

    } catch ({ errors }) {
      dispatch(ERROR(errors[0]));
    }
  };

  const onClickApplyPromo = async () => {
    setValidatingPromoCode(true);
    await dispatch(
      APPLY_PROMO_CODE({ promoCode, eventId: id, tickets: totalTickets })
    );
    setValidatingPromoCode(false);
  };

  const onClickRemovePromo = () => {
    dispatch(SET_PROMO_CODE_DETAIL(null));
  };

  const isPromoApplied = Boolean(promoCodeDetails);

  return (
    <>
      <div className="checkout" style={{ margin: "7%", flex: 1 }}>
        <Processing visible={model} title="Creating Your Payment" />
        <Processing
          visible={validatingPromoCode}
          title="Validating Promocode..."
        />

        <Stack spacing={12} direction={{ base: "column", sm: "row" }}>
          {!is425 && (
            <div style={{ flex: 1 }}>
              <div className="img-div">
                <img src={artwork} alt="Event" className="cover" />
              </div>
              <HStack spacing={5}>
                <Text
                  fontSize="2xl"
                  color="#FF5E62"
                  style={{ fontFamily: "gilroy-medium", flexGrow: 1 }}
                >
                  {category}
                </Text>
              </HStack>

              <Text
                fontSize={{ base: "lg", sm: "4xl" }}
                style={{ fontFamily: "gilroy-semi-bold", lineHeight: "50px" }}
              >
                {name}
              </Text>
              <Stack spacing={3} direction={{ base: "column", sm: "row" }}>
                <div style={{ flexGrow: 1 }}>
                  <HStack spacing={5} alignItems="center" my={3}>
                    <AiFillClockCircle className="inactive" />
                    <div>
                      <Text fontSize="md" fontWeight="medium">
                        {moment(startTime).format("ddd, DD MMM YYYY")}
                      </Text>
                      <Text fontSize="xs" color="#72757A">
                        {moment(startTime).format("hh:mm A")} to{" "}
                        {moment(endTime).format("hh:mm A")}
                      </Text>
                    </div>
                  </HStack>
                  <HStack spacing={5} alignItems="center" my={4}>
                    <MdLocationOn className="inactive" />
                    <Text fontSize="md" fontWeight="medium">
                      {location}
                    </Text>
                  </HStack>
                </div>
              </Stack>
            </div>
          )}

          <div style={{ flex: 1 }}>
            <HStack spacing={0}>
              <IconButton
                rounded="full"
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  history.goBack();
                }}
                aria-label="Search database"
                icon={<IoIosArrowBack color="black" fontSize={32} />}
              />
              <Text mt="2" fontSize="4xl" fontFamily="gilroy-medium">
                Checkout
              </Text>
            </HStack>

            <Ticket
              data={totalTickets}
              tax={isPaid}
              promoDetail={promoCodeDetails}
            />

            {isPromoApplied && (
              <Stack
                backgroundColor={colors.CHARCOAL_GREY}
                borderRadius={5}
                p={2}
                my={5}
              >
                <Text color={colors.WHISPER}>Promocode Applied</Text>
                <Stack direction="row" justifyContent="space-between">
                  <Text color={colors.REDDISH_ORANGE} fontWeight="bold">
                    {promoCodeDetails.code}
                  </Text>
                  <Text color={colors.WHITE} fontWeight="bold">
                    ${promoCodeDetails.amount_off?.toFixed(2)}
                  </Text>
                </Stack>
              </Stack>
            )}

            <InputGroup>
              <Input
                py={6}
                placeholder="Enter Promocode (Optional)"
                disabled={isPromoApplied}
                value={promoCode}
                onChange={(event) =>
                  setPromoCode(event.target.value.toUpperCase())
                }
              />
              <InputRightElement height={"100%"} right={15}>
                {isPromoApplied ? (
                  <Button
                    paddingX={35}
                    marginRight={5}
                    variant="ghost"
                    color={colors.REDDISH_ORANGE}
                    disabled={!promoCode.length}
                    onClick={onClickRemovePromo}
                  >
                    REMOVE
                  </Button>
                ) : (
                  <Button
                    paddingX={30}
                    variant="ghost"
                    color={colors.GREEN_TEXT_COLOR}
                    disabled={!promoCode.length}
                    onClick={onClickApplyPromo}
                  >
                    APPLY
                  </Button>
                )}
              </InputRightElement>
            </InputGroup>

            <Stack spacing={3} my="4" direction={"column"}>
              <Stack spacing={3} direction={{ base: "column", sm: "row" }} style={{ alignItems: "center" }}>
                <Input
                  py="6"
                  placeholder="Name*"
                  value={form.name}
                  onChange={(event) =>
                    setForm({ ...form, name: event.target.value })
                  }
                  size="md"
                  variant="filled"
                  style={{ backgroundColor: "rgba(0,0,0,0.03)", fontSize: 16 }}
                />
                <InputGroup size="lg">
                  <InputLeftAddon style={{
                    gap: "5px",
                    alignItems: "center",
                    padding: "12px",
                  }}>
                    <Image
                      src={flag}
                      alt="U.S Flag"
                      style={{
                        objectFit: "contain",
                        height: "1.5em",
                      }} />
                    <Text>+1</Text>
                  </InputLeftAddon>
                  <Input
                    type={'number'}
                    maxLength={10}
                    minLength={10}
                    value={form.phoneNumber}
                    onChange={(event) => {
                      const newStr = event.target.value.replace(/-/g, "");
                      setForm({ ...form, phoneNumber: newStr })
                    }
                    }
                    placeholder="Phone Number"
                    variant="filled"
                    style={{ backgroundColor: "#00000008", fontSize: 16 }}
                  />
                </InputGroup>
              </Stack>
              <Stack spacing={3} alignItems='center' direction={{ base: "column", sm: "row" }}>
                <Input
                  py="6"
                  value={form.email}
                  onChange={(event) =>
                    setForm({ ...form, email: event.target.value })
                  }
                  placeholder="Email Address"
                  size="md"
                  variant="filled"
                  style={{ backgroundColor: "rgba(0,0,0,0.03)", fontSize: 16 }}
                />
              </Stack>
            </Stack>
            <Stack spacing={3} direction={{ base: "column", sm: "row" }}>
              <Button
                width="full"
                className="inactive"
                variant="solid"
                onClick={() => {
                  history.push("/" + id);
                }}
              >
                CANCEL
              </Button>
              <Button
                width="full"
                className="active"
                variant="solid"
                onClick={handleCheckout}
              >
                Purchase Ticket
              </Button>
            </Stack>
            <Stack
              direction={{ base: "column", sm: "row" }}
              alignItems="center"
              marginTop="6"
              justifyContent="flex-start"
              spacing={2}
              className="poweredBy"
            >
              <img
                src={"/images/powered-by-tempo.png"}
                alt="Powered by Tempo"
                className="tempo"
              />
              <img
                src={"/images/stripePoweredBy.png"}
                alt="stripe Powered by"
                className="stripe"
              />
            </Stack>
          </div>
        </Stack >
      </div >

      <StripeCheckout
        open={Boolean(intentInfo)}
        intentInfo={intentInfo}
        onClose={() => setIntentInfo(null)}
      />
    </>
  );
};

export default Checkout;
