import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { initGA, logPageView } from "./googleAnalytics.helper";
const GoogleAnalytics = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Initialize Google Analytics
    initGA();

    // Log the initial pageview
    logPageView();

    // Log pageview on route change
    const unlisten = history.listen(() => {
      logPageView();
    });

    return () => unlisten();
  }, [history, location]);

  return null; 
};

export default GoogleAnalytics;
