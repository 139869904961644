import { combineReducers } from "redux";
import message from "./message";
import user from "./user";
import events from "./events";

export default combineReducers({
  User: user,
  Event: events,
  Message: message,
});
